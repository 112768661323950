import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cadastro } from '../shared/cadastrar/cadastro';
import { CadastrarService } from '../shared/cadastrar/cadastrar.service';
import { State } from '../shared/state/state';
import { StateService } from '../shared/state/state.service';
import { CityService } from '../shared/city_uf/city.service';
import { City } from '../shared/city_uf/city';
import { Utils } from '../shared/utils';
import {UntypedFormControl} from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.scss']
})
export class CadastrarComponent implements OnInit {

  dtRow: Cadastro;
  stateLst: State[] = [];
  cityLst: City[] = [];
  filteredCities: City[] = [];
  cityStr = '';
  hideList = false;
  pass1: String = '';
  passConf: String = '';
  birth_date: String = '';
  cpf: String = '';
  cityCtrl = new UntypedFormControl();

  constructor(private cadastroSvc: CadastrarService, private router: Router,
    private stateSvc: StateService, private citySvc: CityService,
    private ngxLoader: NgxUiLoaderService) {
    this.dtRow = new Cadastro();
  }

  ngOnInit() {
    this.getStates();
  }

  getStates() {

    this.ngxLoader.start();
    this.stateSvc.getRows().subscribe(
      (result) => {
        this.stateLst = result;
        this.ngxLoader.stop();
      },
      (erro) => {
        this.ngxLoader.stop();
        console.log(erro);
        console.log("erro ao carregar estados");
      }
    );
  }

  selectState() {
    this.ngxLoader.start();
    this.citySvc.getRows(this.dtRow.state_id).subscribe(
      (rows) => {
        this.ngxLoader.stop();
        this.cityLst = rows;
      },
      () => {
        this.ngxLoader.stop();
        alert('Não foi possivel carregar as cidades, por favor tente novamente mais tarde.');
      }
    );
  }

  searchCity(event) {
    if (this.cityStr.length == 0 || this.hideList == true) {
      this.filteredCities = [];
      this.hideList = false;
      return;
    }

    this.filteredCities = this.cityLst.filter((city) => city.name.toLowerCase().indexOf(this.cityStr.toLowerCase()) === 0);
    // this.filteredCities = this.cityLst.filter((city) => {
    //   if (city.name.toLowerCase().startsWith(this.cityStr.toLowerCase())) {
    //     return true;
    //   }
    //   return false;
    // });
  }

  onCitySelected(c: City) {
    this.dtRow.city_id = c.city_id;
    this.cityStr = c.name;
    this.hideList = true;
  }

  callRegister() {
    // para salvar com pontos e tracos

    // this.dtRow.birth_date = this.convertDateToDBNotation(this.birth_date);

    this.dtRow.birth_date = Utils.convertDateToDBNotation(this.birth_date);

    if (this.dtRow.city_id == null || this.dtRow.city_id == undefined
      || this.dtRow.state_id == null || this.dtRow.state_id == undefined
      || this.dtRow.name == null || this.dtRow.name == ''
      || this.dtRow.birth_date == null || this.dtRow.birth_date == ''
      || this.dtRow.phone == null || this.dtRow.phone == ''
      || this.dtRow.email == null || this.dtRow.email == ''
      || this.pass1 == null || this.pass1 == ''
      || this.cpf == null || this.cpf == ''
    ) {
      alert('Preencha todos os campos com *, para concluir seu cadastro.');
      return;
    }

    if (this.notAValidCpf()) {
      alert("Esse CPF não é válido");
      return;
    }

    if (this.pass1 !== this.passConf) {
      alert('Senha e confirmação de senha estão diferentes')
      this.passConf = '';
      return;
    }
    this.dtRow.password = this.pass1;

    if (!this.dtRow.check) {
      alert('Para se cadastrar é necessário concordar com os termos de uso!');
      return;
    }

    this.dtRow.codigo = this.cpf;

    this.ngxLoader.start();
    this.cadastroSvc.cadastrar(this.dtRow).subscribe(
      (result) => {
        this.ngxLoader.stop();
        alert("Cadastrado com sucesso");
        this.router.navigate(["/"]);
      },
      (resError) => {
        this.ngxLoader.stop();
        console.log(resError);
        alert(resError);
      }
    );
  }

  notAValidCpf() {
    if (this.cpf.length != 14) {
      return true;
    }
    const cpfForm = this.cpf.split('.');
    const cpfLast = cpfForm[2].split('-');
    // console.log(cpfForm);
    // console.log(cpfLast);
    this.dtRow.codigo = cpfForm[0] + cpfForm[1] + cpfLast[0] + cpfLast[1];
    this.dtRow.codigo = this.dtRow.codigo.substring(0, 11);
    // console.log(this.dtRow.codigo);
    // this.cpf;
    let rangeDig = 10;
    let soma = 0;
    for (let i = 0; i < this.dtRow.codigo.length - 2; i++) {
      soma += +this.dtRow.codigo[i] * rangeDig;
      rangeDig--;
    }
    let resto = soma % 11;
    //console.log(`soma: ${soma}, resto: ${resto}`);
    //console.log(`1 dig verificador: ${11 - resto} > 9 entao ${this.dtRow.codigo[9]} = 0`);
    //console.log(`1 dig verificador: ${11 - resto} < 9 entao ${this.dtRow.codigo[9]} = ${11 - resto}`);
    if (11 - resto > 9) {
      if (parseInt(this.dtRow.codigo[9]) != 0) {
        return true;
      }
    } else {
      if ((11 - resto) != parseInt(this.dtRow.codigo[9])) {
        return true;
      }
    }

    rangeDig = 11;
    soma = 0;
    for (let i = 0; i < this.dtRow.codigo.length - 1; i++) {
      soma += +this.dtRow.codigo[i] * rangeDig;
      rangeDig--;
    }
    resto = soma % 11;
    // console.log(`soma: ${soma}, resto: ${resto}`);
    // console.log(`2 dig verificador: ${11 - resto} > 9 entao ${this.dtRow.codigo[9]} = 0`);
    // console.log(`2 dig verificador: ${11 - resto} < 9 entao ${this.dtRow.codigo[9]} = ${11 - resto}`);
    if (11 - resto > 9) {
      if (parseInt(this.dtRow.codigo[10]) != 0) {
        return true;
      }
    } else {
      if ((11 - resto) != parseInt(this.dtRow.codigo[10])) {
        return true;
      }
    }

    if (this.dtRow.codigo == '99999999999') {
      return true;
    }
    //
    return false;
  }

}
