import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { BaseService } from '../base.service';
import { CompanyUtils } from '../company-utils';
import { AssinaturaCliente } from './assinatura-cliente';

@Injectable({
  providedIn: 'root'
})
export class AssinaturaClienteService extends BaseService  {

  baseURL = `${Config.vBusUrl}subscription_request_edp/`;
  subscriptionUsageURL = `${Config.vBusUrl}subscription_usage_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getSubscriptions() {
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getSubscriptionRequests() {
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/2`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getSubscriptionUsageForClient(servico_id: number, appuser_id: number, subscription_id: number) {
    return this.http.get(this.subscriptionUsageURL + `${CompanyUtils.getCompanyId()}/${servico_id}/${appuser_id}/${subscription_id}`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  patch(assinatura: AssinaturaCliente) {
    return this.http.patch(this.baseURL + `${CompanyUtils.getCompanyId()}/-1`, JSON.stringify(assinatura))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  post(assinatura: AssinaturaCliente) {

    return this.http.post(this.baseURL + `${CompanyUtils.getCompanyId()}/-1`, JSON.stringify(assinatura))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  delete(id) {
    return this.http.delete(this.baseURL + `/${id}`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }

  restore(id) {
    return this.http.delete(this.baseURL + `/${id}/1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }

  exclude(id) {
    return this.http.delete(this.baseURL + `/${id}/2`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }
}
