import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../svc/globals';

@Component({
  selector: 'app-agenda-home',
  template: '',
})
export class AgendaHomeComponent implements OnInit {

  constructor(
    private globals: Globals,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    // const isSmallScreen = this.breakpointObserver.isMatched(Breakpoints.XSmall); // (max-width: 599.98px)
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 480px)');

    if (isSmallScreen) {
      this.globals.showPageMainContent = false;
      return;
    }

    this.router.navigateByUrl('ambiente/agenda/agendamento-list');
  }

}
