<ngx-ui-loader></ngx-ui-loader>

<div class="main" [ngClass]="{'dark-theme-topbar': currentTheme === 'theme-dark'}">

  <div class="assinatura-title col-12 col-sm-12 d-flex justify-content-between"  [ngClass]="{'dark-theme-topbar': currentTheme === 'theme-dark'}">
    <button class="logoutBtn paddingHorizontalMedium" (click)="goToCompanyLink()" style="cursor: pointer;"  [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
      <h6>Voltar para agenda</h6>
    </button>
    
    <button class="logoutBtn paddingHorizontalMedium" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" (click)="logout()">
      <h6>Sair da conta</h6><img src="../../assets/icons/acesso.png" alt="acesso">
    </button>
  </div>
  
  <div class="agendamento-section-options" [ngClass]="{'dark-theme-bg': currentTheme === 'theme-dark'}">
    <div class="row d-flex justify-content-center marginVerticalMedium">
  
      <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium">
        <div class="selectCardDiv d-flex justify-content-center flex-column"  [ngClass]="{'dark-theme-card': currentTheme === 'theme-dark'}">
          <div class="selectIt">Assinaturas</div>
          <p (click)="homeSubscription()"><input type="radio" id="agendar" name="assinaturas" value="agendar">
          <label for="agendar">Já sou assinante e quero agendar</label><br></p>
          <p><input type="radio" id="assinar" name="assinaturas" value="assinar" checked>
          <label for="assinar">Quero conhecer as assinaturas</label><br></p>
        </div>
      </div>
  
      <div id="ConhecerAssinaturas">
  
        <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium flex-row justify-content-center" *ngIf="assinaturas.length == 0">
          <div class="marginVerticalMedium" style="font-size: 25px; color: green;" [ngClass]="{'dark-theme-bg': currentTheme === 'theme-dark'}">
            <b>Nenhuma assinatura disponível para compra</b>
          </div>
        </div>
  
        <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium" *ngIf="assinaturas.length > 0">
          <div class="selectCardDiv d-flex justify-content-center flex-column"  [ngClass]="{'dark-theme-card': currentTheme === 'theme-dark'}">
            <div class="selectIt">Assinaturas</div>
            <div class="group-cards-assinaturas" [style]="{'margin-bottom':'10px'}">
                <div class="card-assinaturas" [style]="{'margin-bottom':'5px'}" *ngFor="let assinatura of assinaturas; let firstItem = first">
                  <div *ngIf="assinatura.status == 1">
                      <p><input type="radio" id="" name="{{assinatura.name}}" value="{{assinatura.subscription_id}}" [(ngModel)]="selectedSubscriptionId" (change)="setPayment(assinatura)">
                      <label for="{{assinatura.name}}">{{assinatura.name}}
                          por R${{assinatura.price}}</label></p>
                      <p class="descPlano">{{assinatura.description}}</p>
                      <p class="descPlano">Duração: {{assinatura.period}} dias</p>
                      <p class="descPlano">Detalhamento de dias da semana e horários configurados.</p>
                      <p class="descPlano">Válido a partir de: {{assinatura.starts_at.date | date: 'dd/MM/yyyy'}}</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
  
        <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium flex-row justify-content-center" *ngIf="!hasSelectedSubscription && assinaturas.length > 0">
          <div class="marginVerticalMedium" style="font-size: 25px; color: green;" [ngClass]="{'dark-theme-bg': currentTheme === 'theme-dark'}">
            <b>Selecione uma assinatura para escolher um método de pagamento</b>
          </div>
        </div>
  
  
        <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium" *ngIf="hasSelectedSubscription">
          <div class="selectCardDiv d-flex justify-content-center flex-column"  [ngClass]="{'dark-theme-card': currentTheme === 'theme-dark'}">
            <div class="selectIt">Adquira agora mesmo o seu pacote para ter ainda mais benefícios!</div>
            <div class="selectIt" [style]="{'margin-top':'0px'}">Escolha como pagar:</div>
            <div class="payment-methods" *ngFor="let paymentMethod of paymentMethods">
              <p  *ngIf="[1, 7].includes(paymentMethod.payment_method_id)">
                <input type="radio" id="pagamento" name="{{paymentMethod.name}}" value="{{paymentMethod.payment_method_id}}" [(ngModel)]="selectedPaymentMethodId">
                <label for="{{paymentMethod.name}}">{{paymentMethod.name}}</label><br>
              </p>
            </div>
            <div *ngIf="paymentLink != null || paymentLink != undefined">
              <p>
                <input type="radio" id="pagamento" name="Cartão de crédito" value="2" [(ngModel)]="selectedPaymentMethodId">
                <label for="Cartão de crédito">Cartão de crédito</label><br>
              </p>
            </div>
            <div class="selectIt" [style]="{'color':'red'}">ATENÇÃO!</div>
            <div class="selectIt" [style]="{'margin-top':'0px'}">Após o pagamento, envie o comprovante para o estabelecimento liberar a sua compra!</div>
          </div>
        </div>
  
        <div id="pagamentoPIX">
  
          <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium"  *ngIf="selectedPaymentMethodId == 7">
            <div class="selectCardDiv d-flex justify-content-center flex-column"  [ngClass]="{'dark-theme-card': currentTheme === 'theme-dark'}">
              <div class="selectIt">Informações do PIX:</div>
              <div class="pix-container">
                <br>
                <p class="descPix">PIX para pagamento - pague pelo aplicativo de seu banco!</p>
                <br>
                <p class="descPix">Tipo de Chave: {{pixKeyType}}</p>
                <p class="descPix">Pix: {{pixKey}}</p>
                <p class="descPix">Beneficiado: {{nomeContaPix}}</p>
              </div>
              <div class="selectIt" [style]="{'color':'red'}">ATENÇÃO!</div>
              <div class="selectIt" [style]="{'margin-top':'0px'}">Após o pagamento, envie o comprovante para o estabelecimento liberar a sua compra!</div>
            </div>
          </div>
  
        </div>
  
        <div id="pagamentoCartao" *ngIf="selectedPaymentMethodId == 2 && paymentLink != null">
  
          <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium" [ngClass]="{'dark-theme-bg': currentTheme === 'theme-dark'}">
            <div class="selectCardDiv d-flex justify-content-center flex-column">
              <div class="selectIt">Clique no link abaixo para efetuar a sua compra</div>
              <div class="selectIt" [style]="{'margin-top':'6px'}"><a href="{{paymentLink}}" target="_blank" [style]="{'text-decoration':'none'}">{{paymentLink}}</a></div>
              <div class="selectIt" [style]="{'color':'red'}">ATENÇÃO!</div>
              <div class="selectIt" [style]="{'margin-top':'0px'}">Após o pagamento, envie o comprovante para o estabelecimento liberar a sua compra!</div>
            </div>
          </div>
  
        </div>
  
        <div class="row d-flex justify-content-center avanca1Div" id="avanca1Div">
          <button hidden="true" id="openConfirmacaoAgendModal" data-bs-toggle="modal" data-bs-target="#confirmaAgendamentoModal">hidden button</button>
          <button class="avancaBtn" (click)="saveAssinaturaRequest()">Finalizar</button>
        </div>
    
      </div>
    </div>
  </div>
</div>