import { ProductAditional } from './productAditional';
import { Variant } from './variant';
import { ProductImage } from './productImage';
import { ProductOptionName } from './productOptionName';
import { ProductAditionalCateg } from './productAditionalCateg';
import { ProductOptionValue } from './productOptionValue';

export class Product {

    product_id: number;
    company_id: number;
    title: string;
    description: string;
    product_category_id: number;

    price: number = 0.00;
    gender_id: number;
    gender: string;
    vendor: string;
    tags: Array<string>;
    options: Array<string>;
    variants: Array<Variant>;
    images: Array<ProductImage>;

    totalInventory: number;
    metafields = [
        {id: undefined, key: 'width', namespace: 'dimensions', value: '1', valueType: 'INTEGER'},
        {id: undefined, key: 'height', namespace: 'dimensions', value: '1', valueType: 'INTEGER'},
        {id: undefined, key: 'length', namespace: 'dimensions', value: '1', valueType: 'INTEGER'}
    ];

    aditional: Array<ProductAditional>;

    //
    img_path: string;
    product_category_name: string;
    hasAditional: boolean;
    hasVariant: boolean;
    expanded: boolean = false;
    added: boolean = false;
    order_quant: number = 0;
    subtotal: number = 0;
    index_no_pedido; // de zero a pedido.productLst.length
    categoriaName: string;
    obs: string = '';

    productOptions: Array<ProductOptionName>;
    selectedOptions: Array<ProductOptionName>;
  productAditionals: Array<ProductAditionalCateg>;

  optionalsForReview: ProductOptionValue[];
  aditionalsForReview: ProductAditional[];

  optionIsSelected: boolean = false;

  constructor() {
    this.productOptions = [];
    this.selectedOptions = [];
    this.productAditionals = [];
    this.optionalsForReview = [];
    this.aditionalsForReview = [];
  }

}
