import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { CompanyUtils } from './company/company-utils';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from '../interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  objErro: any = {};

  constructor(protected http: HttpClient) {}

  getCommonHeaders_compToken1Conditional(pF: boolean): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    // if (pF){
    //   headers = headers.append('bearer', CompanyUtils.getToken());
    // }
    return headers;
  }

  getCompanyTokenContext(): HttpContext {
    return new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false);
  }

  getAppuserTokenContext(): HttpContext {
    return new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, true);
  }

  handleErrors(error: HttpErrorResponse) {
    if (error.status == 0) {
      return throwError("Houve uma queda temporária de conexão entre este dispositivo e o nosso servidor. Por favor tente novamente em breve.");
    } else if (error.status == 400) {
      return throwError(error.error.message);
    }
    return throwError(error);
  }

}
