import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-topbar-ribbon',
  templateUrl: './subscription-topbar-ribbon.component.html',
  styleUrls: ['./subscription-topbar-ribbon.component.scss']
})
export class SubscriptionTopbarRibbonComponent implements OnInit {

  @Input() subscriptionManageUrl: string;

  constructor() { }

  ngOnInit(): void {
  }

}
