
export class OpenSchedule {
  id: number;
  selected: boolean;
  openScheduleStr: string;
  vagas: number;

  public static returnMockOpenSchedule(){
    let aux = [];
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '08:00', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '08:30', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '09:00', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '09:30', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '10:00', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '10:30', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '11:00', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '11:30', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '13:00', 'vagas': 1});
    aux.push({'id': 0, 'selected': false, 'openScheduleStr': '13:30', 'vagas': 1});
    return aux;
  }
}