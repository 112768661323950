import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { BaseService } from '../base.service';
import { CompanyUtils } from '../company-utils';
import { Agendamento } from '../../agendamento/agendamento';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService extends BaseService  {

  baseURL = `${Config.vBusUrl}agendamento_edp/`;
  recorrenciaURL = `${Config.vBusUrl}agendamento_recorrencia/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAll(date) {
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/${date}/-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }

          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getCount() {
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getDashboard(companyId, dataInicio?, dataFim?) {
    return this.http.get(this.baseURL + `${companyId}/${dataInicio}/${dataFim}/-1`, {

    })
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  // getStatsByPeriod(companyId, dataStart, dataEnd) {
  //   return this.http.get(this.baseURL + `${companyId}/${dataStart}/${dataEnd}/-2`, {
  //     headers: this.getCommonHeaders(true)
  //   })
  //     .pipe(
  //       map((data: any) => {
  //         //
  //         if (data.status != "200") {
  //           catchError(this.handleErrors);
  //         }
  //         return data.result;
  //       }),
  //       catchError(this.handleErrors)
  //     );
  // }

  patch(obj: Agendamento) {

    obj.company_id = CompanyUtils.getCompanyId();

    return this.http.patch(this.baseURL, JSON.stringify(obj))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  post(agendamento: Agendamento) {

    return this.http.post(this.baseURL, JSON.stringify(agendamento))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  delete(id) {
    const company_id = CompanyUtils.getCompanyId();
    return this.http.delete(this.baseURL + `${company_id}/${id}/-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }

  getAllForHistory(page, dateStart?) {
    const httpParamsObj = this.mountParams({dateStart});
    const httpParams = new HttpParams(httpParamsObj);

    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/${page}/-2`, {

      params: httpParams
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getForCommission(date_start, date_end) {
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}/${date_start}/${date_end}/-3`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  /**
   * Deprecated. Check if endpoint of Agendamento supports a list
   * @param list
   * @returns
   */
  postList(list: Agendamento[]) {

    const postObj = {company_id: CompanyUtils.getCompanyId(), list: list}

    return this.http.post(this.recorrenciaURL, JSON.stringify(postObj))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  mountParams(paramObj: Object) {
    let ret = {};
    for (const[key, value] of Object.entries(paramObj)) {
      if (value) {
        ret[key] = value;
      }
    }
    return {
      fromObject: ret
    };
  }


}
