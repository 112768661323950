import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Config } from '../../config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlanService extends BaseService {
  baseURL = `${Config.vBusUrl}plan_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getPlans(pCompany_id, plan_id, getHiddenPlans: boolean){
    const httpParams = new HttpParams({
      fromObject: {
        plan_id: plan_id,
        getHiddenPlans: getHiddenPlans ? 1 : 0,
        system_platform: 2
      }
    });
    return this.http.get(this.baseURL + "-1/-1/" + pCompany_id, {

      params: httpParams
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  // getPlansWithoutTrial(){
  //   return this.http.get(this.baseURL + "-1/-2", {
  //     headers: this.getCommonHeaders(true)
  //   })
  //   .pipe(
  //     map((data: any) => {
  //         //
  //         if (data.status != "200") {
  //             catchError(this.handleErrors);
  //         }
  //         return data.result;
  //     }),
  //     catchError(this.handleErrors)
  //   );
  // }

  getPlanId(id){
    return this.http.get(this.baseURL + id + "/-2")
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  // getPlanWithDiscount(promo_code){
  //   return this.http.get(this.baseURL + promo_code + "/-1", {
  //     headers: this.getCommonHeaders(true)
  //   })
  //   .pipe(
  //     map((data: any) => {
  //         //
  //         if (data.status != "200") {
  //             catchError(this.handleErrors);
  //         }
  //         return data.result;
  //     }),
  //     catchError(this.handleErrors)
  //   );
  // }

  getPurchasedPlanForCompany(id){
    return this.http.get(this.baseURL + "-2/" + id)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

}
