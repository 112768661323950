<!-- [ngClass]="{'selectedCardChoice' : (modalidade_frete === 1)}" -->
<!-- [ngStyle]="{'background-color': switchBackColor(fdi)}" -->

<ngx-ui-loader></ngx-ui-loader>


<div id="unavailableDiv" class="unavailableDiv" *ngIf="company.blocked_by_payment" [style.height.px]="unavailableDivHeight">

    <img src="assets/utilities/block_black_24dp.svg" alt="Indisponivel">

    <div>
      <h2>Link indisponível.</h2>
      <p>Entre em contato com o estabelecimento</p>
    </div>

</div>

<ng-template #content let-modal>
  <div class="modal-content" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}">
  <div class="modal-header backToAgendaModalHeader">
    <button type="button" id="dismissContentModal" class="btn-close" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    <h5 class="modal-title" id="confirmaAgendamentoModalHead">Voltar para agenda</h5>
  </div>
  <div class="modal-body" [class]="[currentTheme]">

    <div id="confirmaAgModalBody" class="modal-body">

      <div class="col">

        <div class="row">
          <p class="confAgendP text-uppercase">Confirmar agendamento</p>
        </div>

        <div *ngFor="let a of agendamentoCart">

          <div class="row alignLeft">
            <div class="col-2">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/servico.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>{{a.servico_name}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="col-2">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/prestador.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>{{a.prestador_name}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="col-2">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/horario.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>Dia {{a.data_agendamentoView}} às {{a.time_start}}, duração: {{a.duracao}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="col-2">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/local.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>{{company.address_complete}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="col-2">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/moedas.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p *ngIf="a.preco_mode == 1">R${{a.preco*a.vagas | priceBR}}</p>
              <p *ngIf="a.preco_mode == 2">Preço sob consulta</p>
              <p *ngIf="a.preco_mode == 3">A partir de R${{a.preco*a.vagas | priceBR}}</p>
            </div>
          </div>

          <div class="d-flex justify-content-center" style="margin-top: 10px;">
            <p class="confAgendP text-uppercase" style="margin-bottom: 8px;">Preencha os campos</p>
          </div>

          <div class="alignLeft" *ngFor="let n of a.namesNgFor; let i = index">
            <label for="nome-cliente" class="form-label confirmaFieldLabels">Nome *</label>
            <div class="input-group mb-3">
              <input type="text" maxlength="100" class="form-control" id="nome-cliente" [(ngModel)]="a.names[i]">
            </div>
          </div>

          <div class="alignLeft">
            <label for="telefone-cliente" class="form-label confirmaFieldLabels">Telefone *</label>
            <div class="input-group mb-3">
              <input type="text" inputmode="tel" class="form-control" maxlength="15" id="telefone-cliente" [(ngModel)]="a.telefone_cliente" mask="(00) 00000-0000" [dropSpecialCharacters]="false">
            </div>
          </div>

          <div class="alignLeft" *ngIf="vagasDisponiveis > 1">
            <label for="vagas-cliente" class="form-label confirmaFieldLabels">Reservar para quantos ({{vagasDisponiveis}} disponíveis) *</label>
            <div class="input-group mb-3">
              <input type="number" maxlength="6" inputmode="numeric" class="form-control" id="vagas-cliente" [(ngModel)]="a.vagas" (ngModelChange)="updateNames(a)">
            </div>
          </div>

          <div class="alignLeft">
            <label for="nome-cliente" class="form-label confirmaFieldLabels">Observação (Opcional)</label>
            <div class="input-group mb-3">
              <input type="text" maxlength="100" class="form-control" id="nome-cliente" [(ngModel)]="a.obs">
            </div>
          </div>

          <div class="alignLeft" *ngIf="company.campo_flexivel_label">
            <label for="campo-flex" class="form-label confirmaFieldLabels">{{company.campo_flexivel_label}} {{company.is_campo_flexivel_mandatory ? '*' :'(Opcional)'}}</label>
            <div class="input-group mb-3">
              <input type="text" maxlength="100" class="form-control" id="campo-flex" [(ngModel)]="a.campo_flexivel">
            </div>
          </div>

        </div>

        <div *ngIf="company.pix_enable">

          <div class="autoLayout flex-column align-items-start">
            <label class="marginVerticalMedium">PIX para pagamento antecipado</label>

            <label>Chave {{pixKeyType}}</label>
            <label>{{pixKey}}</label>
            <label>Beneficiado: {{nomeContaPix}}</label>
          </div>

          <div class="autoLayout marginVerticalMedium flex-column w-100" *ngIf="company.price_perc">
            <label class="marginVerticalSmall text-center w-100">Pague {{company.price_perc * 100}}% no momento do agendamento e envie o comprovante pelo WhatsApp</label>
            <label class="text-center w-100">Valor de pagamento: {{pixValue | currency: 'BRL'}}</label>
          </div>

        </div>

      </div>

    </div>

    <div class="row d-flex justify-content-center marginVerticalSmall">
      <button class="avancaBtn backgroundMainColorPrimary500" (click)="salvarAgendamento()">Finalizar Agendamento</button>
    </div>

    <div class="row d-flex justify-content-center marginVerticalSmall">
      <button class="avancaBtn backgroundMainColorSecondary500" (click)="continueBooking()">Escolher mais serviços</button>
    </div>

  </div>
</div>
</ng-template>


<!-- Lista de espera modal -->
<ng-template #espera let-modal>
  <div class="modal-header backToAgendaModalHeader" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}">
    <button type="button" id="dismissEsperaModal" class="btn-close" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    <h5 class="modal-title" id="confirmaAgendamentoModalHead">Voltar para agenda</h5>
  </div>
  <div class="modal-body">

    <div id="confirmaAgModalBody" class="modal-body">

      <div class="col">

        <div class="row">
          <p class="confAgendP text-uppercase">Entrar em lista de espera para</p>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/servico.png" alt="icon">
          </div>
          <div class="col-10">
            <p>{{agendamentoAFazer.servico_name}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/prestador.png" alt="icon">
          </div>
          <div class="col-10">
            <p>{{agendamentoAFazer.prestador_name}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/horario.png" alt="icon">
          </div>
          <div class="col-10">
            <p>Dia {{agendamentoAFazer.data_agendamentoView}}, duração: {{agendamentoAFazer.duracao}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/local.png" alt="icon">
          </div>
          <div class="col-10">
            <p>{{company.address_complete}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/moedas.png" alt="icon">
          </div>
          <div class="col-10">
            <p *ngIf="agendamentoAFazer.preco_mode == 1">R${{agendamentoAFazer.preco*agendamentoAFazer.vagas | priceBR}}</p>
            <p *ngIf="agendamentoAFazer.preco_mode == 2">Preço sob consulta</p>
            <p *ngIf="agendamentoAFazer.preco_mode == 3">A partir de R${{agendamentoAFazer.preco*agendamentoAFazer.vagas | priceBR}}</p>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 10px;">
          <p class="confAgendP text-uppercase" style="margin-bottom: 8px;">Preencha os campos</p>
        </div>

        <label for="nome-cliente" class="form-label confirmaFieldLabels">Nome *</label>
        <div class="input-group mb-3">
          <input type="text" maxlength="100" class="form-control" id="nome-cliente" [(ngModel)]="agendamentoEspera.nome_cliente">
        </div>

        <div class="alignLeft">
          <label for="telefone-cliente" class="form-label confirmaFieldLabels">Telefone *</label>
          <div class="input-group mb-3">
            <input type="text" inputmode="tel" class="form-control" maxlength="15" id="telefone-cliente" [(ngModel)]="agendamentoEspera.telefone_cliente" mask="(00) 00000-0000" [dropSpecialCharacters]="false">
          </div>
        </div>

      </div>

    </div>

    <div class="row d-flex justify-content-center">
      <button class="avancaBtn backgroundMainColorPrimary500" (click)="salvarListaEspera()">Avançar</button>
    </div>

  </div>
</ng-template>




<!-- div confirmacao fim -->
<div class="modal modaisConfirm" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}" id="confirmsBookingDone" tabindex="-1" aria-labelledby="confirmsBookingDone" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content text-center" [ngClass]="{'box-shadow-dark': currentTheme === 'theme-dark'}">
      <div class="modal-header backgroundNeutralColor100">
        <h5 class="modal-title">Agendamento realizado com SUCESSO</h5>
        <button hidden type="button" class="close" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" data-bs-dismiss="modal" aria-label="Close" id="dismissconfirmsBookingDone">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body d-flex flex-column backgroundNeutralColor100">

        <div class="justify-content-center marginVerticalMedium">
          <img class="greenOkIcon" src="assets/agendamento-publ/greenOkIcon.png" alt="">
        </div>

        <div>
          <p>Seu agendamento foi enviado para o estabelecimento.</p>
        </div>

      </div>

    </div>
  </div>
</div>
<button hidden="true" id="openconfirmsBookingDone" data-bs-toggle="modal" data-bs-target="#confirmsBookingDone">Hidden Button</button>
<!-- div confirmacao fim -->

<!-- div envio mensagem whats -->
<div class="modal" id="enviaMensagemWhats" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}" tabindex="-1" aria-labelledby="enviaMensagemWhats" aria-hidden="true">
  <!-- modal-fullscreen -->
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modaisConfirm" [class]="[currentTheme]">
    <div class="modal-content text-center" [ngClass]="{'box-shadow-dark': currentTheme === 'theme-dark'}">
      <div class="modal-header">
        <h5 class="modal-title">Finalize pelo WhatsApp</h5>
      </div>

      <div class="modalconfirm modal-body d-flex flex-column">

        <div class="justify-content-center marginVerticalMedium">
           <img class="greenOkIcon" src="assets/agendamento-publ/greenOkIcon.png" alt="">
        </div>

        <div>
          <p style="margin-bottom: 0px;">Agora envie a mensagem pelo</p>
          <p>WhatsApp.</p>
        </div>

        <div>
          <p>O estabelecimento já recebeu o seu pedido, mas se certifique enviando a mensagem pelo WhatsApp</p>
        </div>


      </div>

    </div>
  </div>
</div>
<button hidden="true" id="openEnviaMensagemWhats" data-bs-toggle="modal" data-bs-target="#enviaMensagemWhats">Hidden Button</button>
<!-- div prompt envio mensagem whats -->



<!-- div confirmacao espera -->
<div class="modal modaisConfirm" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}" id="confirmsEsperaDone" tabindex="-1" aria-labelledby="confirmsEsperaDone" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content text-center" [ngClass]="{'box-shadow-dark': currentTheme === 'theme-dark'}">
      <div class="modal-header">
        <h5 class="modal-title">SUCESSO</h5>
      </div>

      <div class="modal-body d-flex flex-column">

        <div class="justify-content-center marginVerticalMedium">
          <img class="greenOkIcon" src="assets/agendamento-publ/greenOkIcon.png" alt="">
        </div>

        <div>
          <p>Seu agendamento foi adicionado na lista de espera do estabelecimento.</p>
        </div>

        <div>
          <button class="agendarBtn backgroundColorBlue2271A0" data-bs-dismiss="modal">Finalizar</button>
        </div>

      </div>

    </div>
  </div>
</div>
<button hidden="true" id="openConfirmsEsperaDone" data-bs-toggle="modal" data-bs-target="#confirmsEsperaDone">Hidden Button</button>
<!-- div confirmacao espera -->


<!-- modal sobre -->
<div class="modal fade info" id="exampleModalinfo" tabindex="-1" aria-labelledby="exampleModalinfoLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalinfoLabel">Informações do estabelecimento</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-start">
        <h4 class="modal-info-title text-start">Sobre o estabelecimento: </h4>
        <div class="modal-info-description">
          <p>{{company.description}}</p>
        </div>

        <h4 class="modal-info-title text-start">Endereço: </h4>
        <p class="address">{{company.address_complete}} - {{company.city_name}} - {{company.state_name}}</p>
      </div>
      <div class="modal-footer-info">
        <button type="button" class="btn close-info" data-bs-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
<!-- end modal sobre -->


<div class="modal fade info" id="acessoModal" tabindex="-1" aria-labelledby="acessoModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header backToAgendaModalHeader" [class]="[currentTheme]">
        <button hidden type="button" id="dismissAcessoModal" data-bs-dismiss="modal"></button>
        <h5 class="modalAcesso" id="confirmaAgendamentoModalHead">REALIZE O SEU ACESSO</h5>
      </div>
      <div class="modal-body">

        <div class="row d-flex justify-content-center marginVerticalMedium paddingHorizontalMedium">
          <button class="btnContainedOrange borderRadiusBig textMedium paddingVerticalMedium" style="background: #367925; border: none; text-transform: uppercase;" (click)="navigateToHome()">Acesso do estabelecimento</button>
        </div>

        <div class="row d-flex justify-content-center marginVerticalBig paddingHorizontalMedium">
          <button class="btnContainedOrange borderRadiusBig textMedium paddingVerticalMedium" style="text-transform: uppercase;" (click)="navigateToAcessoCliente()">Acesso do cliente</button>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="agendamentoRootDiv" [class]="[currentTheme]">

  <div class="banner">
    <img src="{{company.img_banner_link}}" class="cover" alt="">
  </div>

  <div class="bannerProfileImg"
  *ngIf="company.img_cartao_link != undefined"
  [style.backgroundColor]="currentTheme === 'theme-dark' ? '#0D0D0C' : '#f2f2f3'">
 <div class="profileDiv">
      <img src="{{company.img_cartao_link}}" class="img-fluid profile" alt="">
    </div>
  </div>

  <div id="companyHeaderInfo" class="row mw-100 ms-0">
    <div class="col-12 d-flex align-items-center header-container">

        <button class="aboutBtn" data-bs-toggle="modal" data-bs-target="#exampleModalinfo" style="position: relative; left: 7%;">
            <img src="assets/icons/about.png" alt="info" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
        </button>

        <p class="company-name">{{company.nome_fantasia}}</p>

        <div class="icon-container">
          <a [attr.href]="'https://api.whatsapp.com/send?phone=' + formatWhatsAppNumber(company.whatsapp_phone)" target="_blank">
              <button class="aboutBtn">
                  <img src="assets/utilities/whatsapp.png" alt="info" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
              </button>
          </a>
        
          <!-- <a [attr.href]="link_social" target="_blank">
              <button class="aboutBtn">
                  <img src="../../assets/icons/acesso.png" alt="acesso" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
              </button>
          </a> -->
        </div>

    </div>
    </div>


  
  

  <!-- <ng-template *ngIf="company.description != undefined && company.description != ''"> -->
    <div class="autoLayout paddingVerticalSmall paddingHorizontalMedium w-100 apply-theme">
      <label class="body1Regular textMedium neutralColor600 text-center">{{company.description}}</label>
    </div>
  <!-- </ng-template> -->

  <p class="company-links">Links do estabelecimento</p>

  <div class="agendamento-section-options" >
    <button class="btnmeusag" *ngFor = "let link of links" (click)="goToLink(link)">{{link.title}}</button>
  </div>


</div>
