import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyNotFoundComponent } from './company-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyNotFoundRoutingModule } from './company-not-found-routing.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';



@NgModule({
  declarations: [
    CompanyNotFoundComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    CompanyNotFoundRoutingModule,
  ],
  exports: [
    CompanyNotFoundComponent
  ]
})
export class CompanyNotFoundModule { }
