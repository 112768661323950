<ngx-ui-loader></ngx-ui-loader>

<div class="not-found-element overlay-foreground">

  <div class="not-found-div">

    <div>
      <h3>Estabelecimento <span class="uppercase">não encontrado</span>!</h3>
    </div>

    <div class="text-center">
      <img src="assets/icons/lupa.png" alt="lupa">
    </div>

    <div class="margin-top-15">
      <p class="text-center">Favor verificar se o link está correto!</p>
    </div>

    <div>
      <p class="text-center">Ou busque os estabelecimentos em sua cidade</p>
    </div>

    <div>
      <form [formGroup]="searchCompaniesForm" action="searchCompanies()">

        <div class="autoLayout flex-column align-items-start">
          <select name="delivery" class="inputEnabled inputLight w-100" formControlName="state_id" (change)="getCities()">
            <option selected disabled [value]="0">Estado</option>
            <option *ngFor="let s of states" [value]="s.state_id">{{s.name}}</option>
          </select>
        </div>

        <div class="autoLayout flex-column align-items-start margin-top-15">
          <button class="inputEnabled inputLight w-100" data-bs-toggle="modal" data-bs-target="#citySelection" style="text-align: start; padding-left: 5px;">
            {{searchCompaniesForm.value.cityName}}
            <i class="fa fa-angle-down" aria-hidden="true" style="float: right; color: #8C8E90; font-size: 15px; font-weight: bold; padding: 3px 0px;"></i>
          </button> 
        </div>

      </form>
    </div>

    <div class="company-list">
      <ng-container *ngFor="let company of companyList; let elementIndex = index;">
        <div class="company-list-item" (click)="goToCompany(company)">
          <label>{{company.nome_fantasia}}</label>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="queryHappened && companyList.length === 0">
      <div style="text-align: center;">
        <label>Não há estabelecimentos cadastrados nessa cidade</label>
      </div>
    </ng-container>

  </div>

</div>

<button hidden="true" type="button" data-bs-toggle="modal" data-bs-target="#citySelection" aria-label="Close" id="dismissCitySelection">
  <span aria-hidden="true">&times;</span>
</button>

<div class="modal fade" id="citySelection" tabindex="-1" aria-labelledby="citySelection" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">

      <div style="position: relative; width: 100%;">
        <label style="width: 100%; text-align: center; font-weight: bold;">Cidade</label>
      </div>
  
      <div style="position: relative; width: 100%; text-align: start;">
        <i class="fa fa-search" aria-hidden="true"></i>
        <input class="input-style-address" type="search" [(ngModel)]="cityStr" style="width: 90%;"
        (ngModelChange)="searchCity()" name="citySearch" placeholder="Pesquisar...">
      </div>
  
      <div style="position: relative; width: 100%; border-bottom: 1px solid grey; margin-top: 1rem;">
        <label style="width: 100%; text-align: start; font-weight: bold; font-weight: normal; margin-left: 2rem;">Selecione a cidade...</label>
      </div>
  
      <div style="overflow-y: scroll; position: relative; max-height: 65%; min-height: 65%; width: 100%; border-bottom: 1px solid grey;">
        <div *ngFor="let s of filteredCityLst" (click)="onCitySelected(s)" style="width: 100%; text-align: start; cursor: pointer; border-bottom: 1px solid grey; height: fit-content; min-height: 3rem; padding-top: 0.5rem;">
          <label style="font-weight: normal; padding-left: 2rem; cursor: pointer;">{{s.name}}</label>
        </div>
      </div>
  
      <div style="position: relative; margin-top: 2rem; display: flex; justify-content: center;">
        <button type="submit" data-bs-toggle="modal" data-bs-target="#newaddr" style="border-radius: 5px; border-style: none; color: white; background-color: orangered; padding: 0.5rem;">Cancelar</button>
      </div>

    </div>
  </div>
</div>