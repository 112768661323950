import { Component, OnInit, OnDestroy } from '@angular/core';
import { Globals } from 'src/app/svc/globals';

@Component({
  selector: 'app-wait-contact',
  templateUrl: './wait-contact.component.html',
  styleUrls: ['./wait-contact.component.scss']
})
export class WaitContactComponent implements OnInit, OnDestroy {

  constructor(public globals: Globals) { }

  ngOnInit(): void {
    this.globals.showTopBar = false;
  }

  ngOnDestroy(): void {
    this.globals.showTopBar = true;
  }

}
