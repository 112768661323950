<body [ngClass]="currentTheme">
  <div class="mainContainer">
    <p class="textoVoltarAgenda" (click)="agendarMais()" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">&lt;</p>
    <p class="textoVoltarAgenda" (click)="agendarMais()" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">Agendar mais</p>
  
    <div class="selectCardDiv"  [ngClass]="currentTheme" *ngFor="let a of agendamentoCart; let i = index">
      <button class="close-button" (click)="removeAgendamento(i)">
        <i style="display:inline" class="fa fa-trash" aria-hidden="true" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" style="font-size: 1em;"></i>
        <p style="display:inline; font-size: 70%; font-weight: bold; text-align: center;" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">Remover</p>
      </button>
              <h1>CONFIRMAR AGENDAMENTO</h1>
        <p style="font-weight: bold;" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">💼Serviço:<br>{{a.servico_name}}</p>
        <p [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"><span style="font-weight: bold;">👤Profissional:</span><br>{{a.prestador_name}}</p>
        <p [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"><span style="font-weight: bold;">📆Dia e horário:</span><br>{{a.data_agendamentoView}} às {{a.time_start}}</p>
        <p [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"><span style="font-weight: bold;">⏳Duração:</span><br>{{a.duracao}}</p>
        <p [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"><span style="font-weight: bold;">📍Endereço:</span><br>{{company.address_complete}}</p>
            <p *ngIf="a.preco_mode == 1" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"><span style="font-weight: bold;">💵Preço:</span><br>{{a.preco*a.vagas | priceBR}}</p>
            <p *ngIf="a.preco_mode == 2" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"><span style="font-weight: bold;">💵Preço sob consulta</span><br></p>
            <p *ngIf="a.preco_mode == 3" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"><span style="font-weight: bold;">💵A partir de R$</span><br>{{a.preco*a.vagas | priceBR}}</p>
    
    </div> 
    <div class="selectCardDiv2">
      <p class="textAgendarMais"  [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" (click)="agendarMais()">Agendar mais serviços</p>
  </div>

  <div>
      <h1>PREENCHA OS CAMPOS</h1>
      <form>
          <div class="divNome">
          <label for="nome" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
          Nome:<br>
          <input class="input" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" type="text" id="nome" name="nomes" [(ngModel)]="nomeClienteForm">
          </label>
          </div>
          <br>

          <label for="telefone" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
          Telefone:<br>
          <input class="input" mask="(00) 00000-0000"
          [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" type="text" id="telefone" name="telefone" placeholder="(XX) XXXXX-XXXX" [(ngModel)]="phoneClienteForm">
          </label>
  

          <br>

          <label for="observacao" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
          Observação (opcional):<br>
          <input class="input" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" type="text" id="observacao" name="observacao" [(ngModel)]="obsClienteForm">
          </label>

          <div *ngIf="company.campo_flexivel_label">
              <label for="campo-flex" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">{{company.campo_flexivel_label}} {{company.is_campo_flexivel_mandatory ? '*' :'(Opcional)'}}</label>
                <input type="text" maxlength="100" class="input" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" id="campo-flex" [(ngModel)]="flexClienteForm">
            </div>
      </form>
  </div>

  <div class="selectCardDiv" *ngIf="company.pix_enable">

      <div class="autoLayout flex-column">
        <label class="marginVerticalMedium" style="font-weight: bold;" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">PIX para pagamento antecipado</label>

        <label [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">Chave {{pixKeyType}}</label>
        
        <div class="campoPix">
          <input #pixInput type="text" readonly [value]="pixKey" style="border: none; background: transparent; flex: 1;"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" />

          <i class="fa fa-copy copyIcon" aria-hidden="true" (click)="copyPixKey(pixInput)"></i>
        </div>

        <label [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">Beneficiado: {{nomeContaPix}}</label>
      </div>

      <div class="autoLayout marginVerticalMedium flex-column w-100" *ngIf="company.price_perc">
        <label class="marginVerticalSmall text-center w-100" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">Pague {{company.price_perc * 100}}% no momento do agendamento e envie o comprovante pelo WhatsApp</label>
        <label class="text-center w-100" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">Valor de pagamento: {{pixValue | currency: 'BRL'}}</label>
      </div>

  </div>

  <button class="finalizaBtn" (click)="finalizar()">Finalizar</button>
  </div>
</body>