import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { CompanyUnlockDate } from './company-unlock-date';
import { CompanyUtils } from '../company-utils';


@Injectable({
  providedIn: 'root'
})
export class CompanyUnlockDateService extends BaseService {
  baseURL = `${Config.vBusUrl}unlock_date_edp/`;

  public companyUnlockDate: CompanyUnlockDate;
  private daysOfTrial: string;
  public endOfPeriodMessage: string = '';
  public hasMessageToShow: boolean = false;

  constructor(private h: HttpClient) {
    super(h);
    this.companyUnlockDate = new CompanyUnlockDate();
  }

  processLogic(){
    this.hasMessageToShow = false;

    // check which is the bigger date
    let auxDate = 
      this.companyUnlockDate.date_end_trial ? new Date(this.companyUnlockDate.date_end_trial.date) : undefined;
    const endTrialTimestamp = 
      auxDate ? auxDate.getTime() : undefined;
    
    auxDate = 
      this.companyUnlockDate.date_end_disblock ? new Date(this.companyUnlockDate.date_end_disblock.date) : undefined;
    const endDisblockTimestamp = 
      auxDate ? auxDate.getTime() : undefined;
    
    let endDateTimestamp;
    let endDate;
    
    const todayDate = new Date(this.companyUnlockDate.today_date.date);
    const todayTimestamp = new Date(this.companyUnlockDate.today_date.date).getTime();
    
    let unlockMessage = '';
    let whichPeriodStr = '';
    this.endOfPeriodMessage = undefined;

    // dependendo de qual data for maior pode mudar a mensagem
    if (endTrialTimestamp >= endDisblockTimestamp) {
      endDateTimestamp = endTrialTimestamp;
      endDate = new Date(this.companyUnlockDate.date_end_trial.date);
      
      whichPeriodStr = 'teste';
      unlockMessage = 'Seu período de teste ';
    } else {
      endDateTimestamp = endDisblockTimestamp;
      endDate = this.companyUnlockDate.date_end_disblock ? new Date(this.companyUnlockDate.date_end_disblock.date) : undefined;

      whichPeriodStr = 'liberação até compensação do pagamento';
      unlockMessage = 'O período de liberação até compensação do pagamento ';
    }

    if (this.companyUnlockDate.assinatura_id == null) {
      // no subscription
      if (this.companyUnlockDate.blocked_by_payment === false) {
        // check dates
        if (endDateTimestamp){
          //
          let daysDiff = this.computeDaysDiff(todayTimestamp, endDateTimestamp);
          if (daysDiff > 0 && daysDiff <= 3){
            this.hasMessageToShow = true;
            const finalStringMessage = `se encerrará em ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
            unlockMessage = unlockMessage.concat(finalStringMessage);
          } else if (daysDiff < 0) {
            this.hasMessageToShow = true;
            daysDiff = this.daysDiffToAbs(daysDiff);
            const finalStringMessage = `se encerrou há ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
            unlockMessage = unlockMessage.concat(finalStringMessage);
          } else if (daysDiff <=1) {
            this.hasMessageToShow = true;
            const finalStringMessage = `se encerra hoje (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
            unlockMessage = unlockMessage.concat(finalStringMessage);
          }
          
        }
        // else is cortesia, does nothing

      } else {
        // blocked, see if has something expired
        if (endDateTimestamp){
          //
          let daysDiff = this.computeDaysDiff(todayTimestamp, endDateTimestamp);
          if (daysDiff > 0 && daysDiff <= 3){
            this.hasMessageToShow = true;
            const finalStringMessage = `se encerrará em ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
            unlockMessage = unlockMessage.concat(finalStringMessage);
          } else if (daysDiff < 0) {
            this.hasMessageToShow = true;
            daysDiff = this.daysDiffToAbs(daysDiff);
            const finalStringMessage = `encerrou há ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
            unlockMessage = 'Seu período de teste ';
            unlockMessage = unlockMessage.concat(finalStringMessage);
          } else if (daysDiff <=1) {
            this.hasMessageToShow = true;
            const finalStringMessage = `se encerra hoje (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
            unlockMessage = unlockMessage.concat(finalStringMessage);
          }
          
        }

      }
    } else {
      // theres subscription

      if (this.companyUnlockDate.status === "unpaid" || this.companyUnlockDate.status === "waiting_payment") {
        // empresa foi liberada na mao
        
        if (this.companyUnlockDate.blocked_by_payment === false){
          if (endDateTimestamp){
            let daysDiff = this.computeDaysDiff(todayTimestamp, endDateTimestamp);
            if (daysDiff > 0 && daysDiff <= 3){
              this.hasMessageToShow = true;
              const finalStringMessage = `se encerrará em ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
              unlockMessage = unlockMessage.concat(finalStringMessage);
            } else if (daysDiff < 0) {
              this.hasMessageToShow = true;
              // nao eh pra acontecer aqui
              daysDiff = this.daysDiffToAbs(daysDiff);
              const finalStringMessage = `encerrou há ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
              unlockMessage = unlockMessage.concat(finalStringMessage);
            } else if (daysDiff <=1) {
              this.hasMessageToShow = true;
              const finalStringMessage = `se encerra hoje (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
              unlockMessage = unlockMessage.concat(finalStringMessage);
            }
          }
        } else {
          // empresa nao pagou e nao foi liberada ou ja expirou a liberacao
          if (endDateTimestamp){
            let daysDiff = this.computeDaysDiff(todayTimestamp, endDateTimestamp);
            if (daysDiff > 0 && daysDiff <= 3){
              this.hasMessageToShow = true;
              const finalStringMessage = `se encerrará em ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
              unlockMessage = unlockMessage.concat(finalStringMessage);
            } else if (daysDiff < 0) {
              this.hasMessageToShow = true;
              // nao eh pra acontecer aqui
              daysDiff = this.daysDiffToAbs(daysDiff);
              const finalStringMessage = `encerrou há ${daysDiff} dias (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
              unlockMessage = unlockMessage.concat(finalStringMessage);
            } else if (daysDiff <=1) {
              this.hasMessageToShow = true;
              const finalStringMessage = `se encerra hoje (dia ${endDate.getUTCDate()}/${endDate.getUTCMonth()+1}/${endDate.getUTCFullYear()})`;
              unlockMessage = unlockMessage.concat(finalStringMessage);
            }
          }
        }
      } else if (this.companyUnlockDate.status === "canceled"){
        this.hasMessageToShow = true;
        unlockMessage = 'Sua assinatura foi cancelada. Escolha um de nossos planos para voltar a utilizar nossos serviços.';
      }
    }

    this.endOfPeriodMessage = unlockMessage;
  }

  computeDaysDiff(dateStartTimestamp, dateEndTimestamp){
    const difference = (dateEndTimestamp - dateStartTimestamp);
    const daysDiff = Math.round( difference / (1000 * 3600 * 24) );

    if (daysDiff <= 1) {
      this.daysOfTrial = daysDiff.toFixed(2).replace('.', ',');
    } else {
      this.daysOfTrial = daysDiff.toFixed(0).replace('.', ',');
    }
    return daysDiff;
  }

  daysDiffToAbs(daysDiff){
    return Math.abs(daysDiff);
  }

  queryUnlockDate(){
    return this.http.get(this.baseURL + `${CompanyUtils.getCompanyId()}`)
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        this.companyUnlockDate = data.result;
        return [];
      }),
      catchError(this.handleErrors)
    );
  }
  

}
