
<!-- end trial days warn -->
<div *ngIf="showRibbon" id="trialTimesMessage">

  <div>
    <p>{{ribbonMessage}}</p>
  </div>
  
  <button (click)="dismissRibbon()">
    <i class="fa fa-close" aria-hidden="true"></i>Fechar
  </button>

</div>