import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from '../../config';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseService {
  baseURL = `${Config.vBusUrl}clients/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAll(companyId, userName) {
    return this.http.get(this.baseURL + `${companyId}/${userName}/-1`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getAniver(companyId) {
    return this.http.get(this.baseURL + `${companyId}/-1`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getRank(companyId) {
    return this.http.get(this.baseURL + `${companyId}/-2`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getMonyhTop(companyId) {
    return this.http.get(this.baseURL + `${companyId}/-3`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getDetails(companyId, appuser_id) {
    return this.http.get(this.baseURL + `${companyId}/${appuser_id}/-2`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result[0];
        }),
        catchError(this.handleErrors)
      );
  }

  getAgendaAniver(companyId) {
    return this.http.get(this.baseURL + `${companyId}/-4`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getAgendaMonyhTop(companyId) {
    return this.http.get(this.baseURL + `${companyId}/-5`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getDetailsAgenda(companyId, appuser_id) {
    return this.http.get(this.baseURL + `${companyId}/${appuser_id}/-3`)
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result[0];
        }),
        catchError(this.handleErrors)
      );
  }

  post(userObj) {
    let obj = JSON.stringify(userObj);
    return this.http.post(this.baseURL, obj)
      .pipe(
        map((data: any) => {

          return data;
        }),
        catchError(this.handleErrors)
      );
  }
}
