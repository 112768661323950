import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export class Agendamento {

    agendamento_id: number;
    company_id: number;
    servico_id: number;
    color: string;
    prestador_id: number;
    data_agendamento: any;
    data_agendamentoView: string;
    time_start: string;
    time_end: string;
    vagas: number;
    nome_cliente: string;
    telefone_cliente: string;
    servico_name: string;
    prestador_name: string;
    payment_method_id: number;
    payment_method_name: string;
    deleted: boolean;
    agendamento_status_id: number;
    appuser_id: number;
    data_de_requisicao: any;

    preco: number;
    precoStr: string;
    preco_mode: number;
    duracao: string;
    obs: string;
    confirmed :boolean;
    celHeight: number;

    isSended: boolean;

    status: number;
    system_source: string;

    timeZone: number;

    recorrenciaDate: NgbDateStruct;

    confirmSended: boolean;


    names = [''];
    namesNgFor = [''];
    campo_flexivel_label: string;
    campo_flexivel: string;

    showDate: boolean = false;

    constructor() {
      this.vagas = 1;
      this.servico_id = 0;
      this.prestador_id = 0;
      this.names = [''];
      this.namesNgFor = [''];
    }
}