

<label *ngIf="isLoading" class="alignTxtCenter" style="width: 100%;">Carregando...</label>

<div *ngIf="!isLoading" class="mainPage">

  <div *ngIf="act == 1">

    <div>
      <div class="centerEl">
          <p class="card-description"><span class="material-icons plan">CANCELAMENTO</span></p>
      </div>
    </div>

    <div>
      <p class="hintLabel2 ion-text-wrap">Por favor revise os termos abaixo:</p>
    </div>

    <div>
      <p class="hintLabel2 ion-text-wrap">Ao cancelar a assinatura, apenas permanecerão ativos os logins (usuários) de sua empresa.</p>
    </div>

    <div>
      <p class="hintLabel2 ion-text-wrap">Não será possível receber ou realizar mais nenhum agendamento.</p>
    </div>

    <div>
      <div class="centerEl">
          <button (click)="tryCancelation()">Cancelar assinatura</button>
      </div>
    </div>


  </div>

  <div *ngIf="act == 2">

    <div>
      <p class="hintLabel2 ion-text-wrap">Por favor revise os termos e valores abaixo:</p>
    </div>

    <div>
      <p class="hintLabel2 ion-text-wrap">Nome do plano: {{newPlan.name}}</p>
    </div>

    <div>
      <p class="hintLabel2 ion-text-wrap">Descrição: {{newPlan.descricao}}</p>
    </div>

    <div>
      <p class="hintLabel2 ion-text-wrap">Valor a ser cobrado: R$ {{newPlan.amount}}</p>
    </div>

    <div>
      <p class="hintLabel2 ion-text-wrap">Ao clicar no botão "Mudar plano", você concorda com as mudanças acima.</p>
    </div>

    <div>
      <div class="centerEl">
          <button (click)="tryChanges()">Mudar plano</button>
      </div>
    </div>


  </div>

  

  

</div>
