import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from '../../config';
import { BaseService } from '../base.service';
import { CompanyUser } from '../company-user/CompanyUser';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class NewPasswordService extends BaseService{
  baseURL = `${Config.vBusUrl}change_password_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  patch(regObj: CompanyUser) {
    let obj = JSON.stringify(regObj);
    return this.http.patch(this.baseURL, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

}
