import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { AssinaturaPublService } from 'src/app/svc/cliente-assinatura/assinatura.service';
import { Assinatura } from 'src/app/svc/company/assinaturas/assinatura';
import { AssinaturaCliente } from 'src/app/svc/company/assinaturas/assinatura-cliente';
import { AssinaturaClienteService } from 'src/app/svc/company/assinaturas/assinatura-cliente.service';
import { Company } from 'src/app/svc/company/company';
import { CompanyService } from 'src/app/svc/company/company.service';
import { Globals } from 'src/app/svc/globals';
import { PaymentMethod } from 'src/app/svc/payment-method/payment-method';

@Component({
  selector: 'app-nova-assinatura',
  templateUrl: './nova-assinatura.component.html',
  styleUrls: ['./nova-assinatura.component.scss'],
})
export class NovaAssinaturaComponent implements OnInit {

  paymentMethod: any = null;
  assinaturas = new Array<Assinatura>;
  pixKeyType: string = '';
  pixKey: string = '';
  nomeContaPix: string = '';
  pixValue: number;
  company = new Company();
  destinationPhone = '';
  paymentMethods = new Array<PaymentMethod>;

  assinaturaRequest = new AssinaturaCliente();

  // Controladores de seleção de cliente, assinatura e forma de pagamento para venda de assinatura
  selectedClientId: any = localStorage.getItem('appuser_id');
  selectedSubscriptionId: any;
  selectedPaymentMethodId: any;
  
  first: boolean = true;

  paymentLink: any = null;
  selectedPayment: Assinatura;

  hasSelectedSubscription:boolean = false;
  currentTheme: string = 'theme-default';
  currentButtonTheme: string = 'button-theme-default';
  constructor(
      private router: Router,
      private ngxLoaderSvc: NgxUiLoaderService,
      private assinaturaPublService: AssinaturaPublService,
      private globals: Globals,
      private companySvc: CompanyService,
      private assinaturaClienteSvc: AssinaturaClienteService,
    ) { }

  ngOnInit(): void {
    this.getCompanySubscriptions();
    this.getCompanyData();
    this.getPaymentMethods();
  }

  getCompanySubscriptions(){
    this.ngxLoaderSvc.start();
    this.assinaturaPublService.getCompanySubscriptions().subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.assinaturas = result;
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
        this.globals.openDialog('Erro', 'Não foi possivel buscar as informações dos serviços');
      }
    );
  }

  getCompanyData() {
    // will return whatsapp_phone and company data
    this.ngxLoaderSvc.start();
    this.companySvc.getAgendamentoCompData(localStorage.getItem('CompanyId')).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.company = result;

        if(this.company.tempo_agendar_antecedencia != undefined){
          this.company.tempo_agendar_antecedencia *= 3600000;
        }

        if(this.company.is_login_mandatory && localStorage.getItem('appuser_id') == undefined){
          this.router.navigateByUrl('client/login');
          return;
        }

        if(this.company.pix_enable){
          const pixInfoArray = this.company.pix_info.split(';');
          this.pixKeyType = pixInfoArray[0];
          this.pixKey = pixInfoArray[1];
          this.nomeContaPix = pixInfoArray[2];
        }

        try {
          this.company.latitude = Number.parseFloat(this.company.latitude.toString());
          this.company.longitude = Number.parseFloat(this.company.longitude.toString());
        } catch (e) {
          this.company.latitude = 0;
          this.company.longitude = 0;
        }
        this.currentTheme = !this.company.linkThemeName ? 'theme-default' : this.company.linkThemeName;
        this.destinationPhone = '+55' + this.company.whatsapp_phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
        alert("Não foi possível carregar os dados da empresa.");
      }
    );
  }

  getPaymentMethods(){
    this.ngxLoaderSvc.start();
    this.assinaturaPublService.getPaymentMethods().subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.paymentMethods = result;

      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
        this.globals.openDialog('Erro', 'Não foi possivel buscar as informações dos serviços');
      }
    );
  }

  homeSubscription(){
    this.router.navigate(['client/assinatura'])
  }

  logout(){
    localStorage.removeItem('appuser_id');
    localStorage.removeItem('appuserToken');
    this.router.navigateByUrl('client/login');
  }

  saveAssinaturaRequest() {
    this.setSubscriptionRequestData();

    if(this.selectedSubscriptionId == undefined || this.selectedPaymentMethodId == undefined)
    {
      this.globals.presentToast("Selecione pelo menos uma assinatura e uma forma de pagamento!");
      return
    }

    this.ngxLoaderSvc.start();
    this.assinaturaPublService.postSubscriptionRequest(this.assinaturaRequest).subscribe({
      next: (result) => {
        this.ngxLoaderSvc.stop();
        this.globals.openRedirectModal(
          "O estabelecimento recebeu seu pedido de assinatura! Você será redirecionado para suas assinaturas.",
          () => {
            this.router.navigate(['client/assinatura'])
          }
        );
      },
      error: (err) => {
        this.ngxLoaderSvc.stop();
        this.globals.openDialog('Erro', "Ocorreu um erro ao cadastrar a assinatura, tente novamente. Se o erro persistir, entre em contato com a empresa");
      }
    });
  }

  setSubscriptionRequestData() {
    this.assinaturaRequest.appuser_id = this.selectedClientId;
    this.assinaturaRequest.subscription_id = this.selectedSubscriptionId;
    this.assinaturaRequest.payment_method = this.selectedPaymentMethodId;
    this.assinaturaRequest.status = 2;
  }

  setPayment(assinatura: Assinatura){
    this.paymentLink = null
    this.selectedPayment = assinatura;
    this.hasSelectedSubscription = true;

    if(this.selectedPayment.payment_link != null || this.selectedPayment.payment_link != undefined)
    {
      this.paymentLink = this.selectedPayment.payment_link;
    }
  }

  goToCompanyLink(){
    this.router.navigateByUrl(this.company.handle);
  }
}
