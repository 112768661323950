import { Workhour } from "../workhour/workhour";
import { PaymentMethod } from '../payment-method/payment-method';
import { CompanyUnlockDate } from "./comp-unlock-date/company-unlock-date";

export class Company {

	public company_id: number;
    public nome_fantasia: string;
    public address_complete: string;
	public state_name: string;
    public city_name: string;
    public category_id: number;
    public category_id_2: number;
    public phone: string;
    receive_whatsapp_msg: boolean;
    public whatsapp_phone: string;
    public email: string;
    public img_cartao_link: string;
    public img_banner_link: string;
    public description: string;
    public frete_dist_maior: number;

    // public horario_seg: string;
    // public horario_ter: string;
    // public horario_qua: string;
    // public horario_qui: string;
    // public horario_sex: string;
    // public horario_sab: string;
    // public horario_dom: string;
    public fb_mess_link: string;
    public latitude: number;
    public longitude: number;
    public hasCartaoImg: boolean;
    public hasTicket: number;
    public hasCard: boolean;
    public categoria_name: string;
    public workhours: Array<Workhour>;
    public prodCount: number;
    companyPayment: PaymentMethod[] = [];
    frete: number;
    handle: string;
    frete_mode_other_locations: number;
    sends_whats_message: boolean;
    tempo_abertura: number;     // dias de tolerancia de abertura de agenda
    blocked_by_payment: boolean;
    company_unlock_date: CompanyUnlockDate;


    tempo_agendar_antecedencia: number;
    campo_flexivel_label: string;
    is_campo_flexivel_mandatory: boolean;
    is_login_mandatory: boolean;

    pix_enable: boolean;
    pix_info: string;
    price_perc: number;

    lista_espera_enable: boolean;
    linkThemeName: string;
    iso4217_code: string;
    currency_symbol: string;
    unicodeLocaleId: string;

    country_id: number;
    city_id: number;
    state_id: number;
}
