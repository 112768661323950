import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompanyUnlockDateService } from '../svc/company/comp-unlock-date/company-unlock-date.service';

@Component({
  selector: 'app-subscription-feedback',
  templateUrl: './subscription-feedback.component.html',
  styleUrls: ['./subscription-feedback.component.scss']
})
export class SubscriptionFeedbackComponent implements OnInit, OnChanges {

  showRibbon: boolean = false;
  ribbonMessage: string = '';
  @Input() triggerInputAux: boolean;

  constructor(
    private companyUnlockSvc: CompanyUnlockDateService
  ) { }

  ngOnInit(): void {
    // this.queryUnlockDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.queryUnlockDate();
  }

  // queryUnlockDate(){
  //   this.showRibbon = false;
  //   this.ribbonMessage = '';
    
  //   this.companyUnlockSvc.queryUnlockDate().subscribe(
  //     (result) => {
        
  //       this.companyUnlockSvc.processLogic();
  //       if (this.companyUnlockSvc.hasMessageToShow){
  //         this.showRibbon = true;
  //         this.ribbonMessage = this.companyUnlockSvc.endOfPeriodMessage;
  //       } else {
  //         this.showRibbon = false;
  //         this.ribbonMessage = '';
  //       }
        
  //     },
  //     (err) => {}
  //   );
  // }

  dismissRibbon(){
    this.showRibbon = false;
  }

}
