
<!-- ############################################################ -->
<!--                              Page                            -->
<!-- ############################################################ -->
<router-outlet></router-outlet>

<!-- ############################################################ -->
<!--                            Dialogs                           -->
<!-- ############################################################ -->

<div *ngIf="globals.showToast" class="toastContainer" [@inOutAnimation] (click)="globals.showToast = false">
  <label style="color: white; font-weight: bold;">{{globals.toast}}</label>
</div>

<!-- toast -->
<ngb-toast
  *ngIf="globals.showToast2"
  header="{{globals.alert.title}}"
  [delay]="3000"
  [autohide]="true"
  class="toastContainer2" >
    <div>
      {{globals.toast}}
    </div>
</ngb-toast>
<!-- end toast -->

<div *ngIf="globals.showConfirm" [@inOutAnimation] class="mainContainer">
  <div class="modal-body noBorder">
      <p>{{globals.confirm}}</p>
  </div>
  <div class="modal-footer noBorder">

      <button type="button" class="btn" (click)="globals.showConfirm = false">Cancelar</button>
      <button type="button" class="btn blueBtn" (click)="globals.runHandler()">Ok</button>
  </div>
</div>

<div *ngIf="globals.showRedirect" [@inOutAnimation] class="mainContainer">
  <div class="modal-body noBorder">
      <p>{{globals.confirm}}</p>
  </div>
  <div class="modal-footer noBorder">
      <button type="button" class="btn blueBtn" (click)="globals.runRedirectHandler()">Ok</button>
  </div>
</div>


<!-- -----------------------------------------------------------------------  -->
<!-- START Alarm Prompt -->
<!-- -----------------------------------------------------------------------  -->
<div *ngIf="globals.showAlarmPrompt" style="position: fixed; width: 100%; height: 100%; top: 0; background-color: black; opacity: 0.6;">

</div>


<div class="alarmPrompt" *ngIf="globals.showAlarmPrompt" style="z-index: 2;">

  <label style="font-weight: 600; margin-top: 2%; width: 100%; text-align: center;">Deseja receber avisos sonoros quando novos pedidos chegarem?</label>

  <div style="display: flex; justify-content: space-evenly; margin-top: 5%;">
    <button class="jumpBtn" style="display: inline-block; width: 30%;" (click)="globals.playAlarm = 2; globals.showAlarmPrompt = false">Avisar até receber</button>
    <button class="jumpBtn" style="display: inline-block; width: 30%" (click)="globals.playAlarm = 1; globals.showAlarmPrompt = false">Avisar uma vez</button>
    <button class="jumpBtn" style="display: inline-block; width: 30%; background-color: white; color: black;" (click)="globals.playAlarm = 0; globals.showAlarmPrompt = false">Não avisar</button>
  </div>

</div>
<!-- -----------------------------------------------------------------------  -->
<!-- END Alarm Prompt -->
<!-- -----------------------------------------------------------------------  -->
<div *ngIf="globals.showPaymentPopup" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;">

  <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: black; opacity: 0.7;"></div>

  <div class="paymentImagesDiv">

    <i [@delayXShowAnimation]="globals.showPaymentPopup ? 'xShown' : 'xHidden'" class="fa fa-times" style="opacity:0; font-size: 12px; color: #db5959; cursor: pointer; position: sticky; top: 5%; padding-left: 95%;" (click)="globals.showPaymentPopup = false;"></i>

    <a href="{{globals.managePaymentUrl}}" target="_blank">
      <img style="width: 100%; position: relative; z-index: 5;" [src]="globals.paymentAlertImg">
    </a>

  </div>

</div>

<div *ngIf="globals.showSubscriptionOver" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;">

  <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: black; opacity: 0.7;"></div>

  <div class="subscriptionOverDiv">
    <a (click)="globals.closeSubscriptionOver()">
      <img style="width: 100%; position: relative; z-index: 5;" [src]="globals.paymentAlertImg">
    </a>
  </div>

</div>

<!-- <div *ngIf="globals.showConnectionFail" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;">

  <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: black; opacity: 0.7;"></div>

  <div style="position: absolute; width: 40%; height: fit-content; left: 30%; border-radius: 5px; background-color: transparent; cursor: pointer;"
        (click)="globals.showConnectionFail = false;">

    <i class="fa fa-times" style="font-size: 25px; color: red; position: sticky; top: 5%; padding-left: 95%;"></i>

    <img style="width: 100%;" src="../assets/ErroInternet.png">

  </div>

</div> -->
