import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../config';
import { BaseService } from '../base.service';
import { CompanyUtils } from '../company/company-utils';
import { AppuserInterface } from './appuser';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';
import { AppuserAddress } from './appuserAddress';

@Injectable({
  providedIn: 'root'
})
export class AppuserService extends BaseService {
  // baseURL = `${Config.vAppUrl}cadastrar_edp/`;
  searchURL = `${Config.vBusUrl}clients_whatsagenda/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  // registerAppuser(appuser: AppuserInterface){
  //   const httpParams = new HttpParams({
  //     fromObject: {
  //       company_id: CompanyUtils.getCompanyId()
  //     }
  //   });
  //   appuser.system_platform = 2;
  //   const obj = JSON.stringify(appuser);
  //   return this.http.post(this.baseURL, obj, {
  //     context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
  //     params: httpParams
  //   })
  //   .pipe(
  //     map((data: any) => {
  //       if (data.status != "200") {
  //         catchError(this.handleErrors);
  //       }
  //       console.log('registro:',data);
  //       return data.message;
  //     }),
  //     catchError(this.handleErrors)
  //   );
  // }

  getClients() {

    let queryParams = new HttpParams().set("company_id", CompanyUtils.getCompanyId());
    queryParams = queryParams.set("allQuery", "allQuery");

    return this.http.get(this.searchURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false),
      params: queryParams
    })
    .pipe(
        map((data: any) => {
            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

  getAddrString(pUserAddr: AppuserAddress, modalidadeParam?: number): string {
    const tmpMod = (modalidadeParam != undefined) ? modalidadeParam : 0;
    let res = pUserAddr.street + ', ' + pUserAddr.number + ', '
      + pUserAddr.district + ', ' + pUserAddr.cityName + ', ' + pUserAddr.uf;

    if (tmpMod === 0) {
      res = pUserAddr.street + ', ' + pUserAddr.number + ', '
        + pUserAddr.district + ', ' + pUserAddr.cityName + ', ' + pUserAddr.uf;
    }
    else if (tmpMod === 1) {
      res = pUserAddr.district + ', ' + pUserAddr.cityName + ', ' + pUserAddr.uf;
    }
    else if (tmpMod === 2) {
      res = pUserAddr.cityName + ', ' + pUserAddr.uf;
    }
    return res;

  }

  getClientsBirthday(offset, maxResults, birthDate, searchTerm?: string) {

    let queryParams = new HttpParams().set("company_id", CompanyUtils.getCompanyId());
    queryParams = queryParams.set("offset", offset);
    queryParams = queryParams.set("maxResults", maxResults);
    queryParams = queryParams.set("birth_date", birthDate);
    queryParams = queryParams.set("birthQuery", "birthQuery");

    if(searchTerm != undefined && searchTerm.length > 0){
      queryParams = queryParams.set("searchTerm", searchTerm);
    }

    return this.http.get(this.searchURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false),
      params: queryParams
    })
    .pipe(
        map((data: any) => {
            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

  getBestClientsWhatsagenda(offset: number, maxResults: number, searchTerm?: string) {

    let httpParams = new HttpParams({
      fromObject: {
        company_id: CompanyUtils.getCompanyId(),
        offset: offset,
        maxResults: maxResults,
        bestQuery: "bestQuery"
      }
    });
    if(!!searchTerm && searchTerm.length > 0){
      httpParams = httpParams.set("searchTerm", searchTerm);
    }
    return this.http.get(this.searchURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false),
      params: httpParams
    })
    .pipe(
      map( (data: any) => {
        return data.result;
      } ),
      catchError(this.handleErrors)
    );
  }

  getClientsFrequencyByRange(offset, maxResults, startDate, endDate, searchTerm?: string) {

    let queryParams = new HttpParams().set("company_id", CompanyUtils.getCompanyId());
    queryParams = queryParams.set("frequencyQuery", 1);
    queryParams = queryParams.set("offset", offset);
    queryParams = queryParams.set("maxResults", maxResults);
    queryParams = queryParams.set("startDate", startDate);
    queryParams = queryParams.set("endDate", endDate);

    if(searchTerm != undefined && searchTerm.length > 0){
      queryParams = queryParams.set("searchTerm", searchTerm);
    }

    return this.http.get(this.searchURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false),
      params: queryParams
    })
    .pipe(
        map((data: any) => {
            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

  /**
   * @deprecated to be substituted by getClientName2 in CompanyClientService
   * @param telefone_cliente
   * @returns
   */
  getClientName(telefone_cliente) {

    let queryParams = new HttpParams().set("nameQuery", 1);
    queryParams = queryParams.set("telefone_cliente", telefone_cliente);

    return this.http.get(this.searchURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false),
      params: queryParams
    })
    .pipe(
        map((data: any) => {
            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

  updateAddressListInStorage(addressList: AppuserAddress[]) {
    const addressListCopy = addressList.map((item) => {
      return {...item, addr_selected: false};
    });
    localStorage.setItem("userAddressLst",  JSON.stringify(addressListCopy) );
  }
}
