import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {

  private callSalvarAgendamentoSubject = new Subject<void>();
  callSalvarAgendamento$ = this.callSalvarAgendamentoSubject.asObservable();

  triggerSalvarAgendamento() {
    this.callSalvarAgendamentoSubject.next();
  }
}
