<div class="autoLayout paddingSmall position-absolute" style="width: 100vw; left: 0;">

  <div class="autoLayout paddingMedium marginVerticalMedium backgroundNeutralColor100 borderRadiusBig shadowBox flex-column">

    <h3 class="headlineBold neutralColor800 marginVerticalMedium text-center">CADASTRO REALIZADO COM SUCESSO!</h3>

    <i class="fa fa-check-square-o mainColorPrimary500" style="font-size: 70px;" aria-hidden="true"></i>

    <h5 class="headlineBold neutralColor600 marginVerticalMedium text-center">Seja bem vindo ao WhatsAgenda</h5>
    <h5 class="headlineBold neutralColor600 text-center">Sua agenda digital</h5>

    <i class="fa fa-phone mainColorPrimary500 marginVerticalSmall" style="font-size: 50px;" aria-hidden="true"></i>

    <h5 class="headlineBold mainColorSecondary500 text-center">Em breve um de nossos consultores entrará em contato com vocẽ! </h5>

    <button class="btnOutlinedOrange textBig marginVerticalBig paddingHorizontalBig" routerLink="/ambiente/agenda/user-profile/1">Continuar configuração de minha agenda</button>

  </div>

</div>