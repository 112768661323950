
export class Cadastro {
    check: boolean;
    name: String;
    birth_date: String;
    sex_id: String;
    phone: String;
    state_id: number;
    city_id: number;
    email: String;
    password: String;
    codigo: String;
}
