import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgendamentoPublService } from '../svc/agendamento/agendamento-publ.service';
import { Globals } from '../svc/globals';
import { AppuserAgendamentoListService } from '../svc/appuser/appuser-agendamento-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Utils } from '../shared/utils';
import { Agendamento } from '../svc/agendamento/agendamento';
import { ServicoPublService } from '../svc/servico/servico-publ.service';
import { ServiceCategory } from '../svc/servico/service-category';
import { AgendamentoStatusService } from '../svc/company/agendamento-status/agendamento-status.service';


@Component({
  selector: 'app-confirma-agendamento',
  templateUrl: './confirma-agendamento.component.html',
  styleUrls: ['./confirma-agendamento.component.scss']
})
export class ConfirmaAgendamentoComponent implements OnInit {

  id:string = '';
  cid:string = '';
  agendamento: any = {};
  agendamentoConfirmado: boolean = false;
  agendamentoCancelado: boolean = false;
  tempoTolerancia: number;
  dataAgendamento: string = '';
  userName: string = '';
  companyName: string = '';
  futureAppointments: Array<any> = new Array<any>();
  previousAppointments: Array<any> = new Array<any>();
  company_id: string;
  serviceCategoryList: ServiceCategory[] = [];
  preco_mode: number;



  constructor(
    private route: ActivatedRoute,
    private AgendamentoPublService: AgendamentoPublService,
    private globals: Globals,
    private agSvc: AppuserAgendamentoListService,
    private ngxLoaderSvc: NgxUiLoaderService,
    private servicoSvc: ServicoPublService,
    private agStatusSvc: AgendamentoStatusService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.cid = params.get('cid');
    });

    localStorage.setItem('CompanyId', this.cid);
    localStorage.setItem('company_id', this.cid);
    this.company_id = this.cid;

    this.AgendamentoPublService.getByAgendamentoId(this.id)
    .subscribe(
      (data) => {
        this.agendamento = data;
        console.log(this.agendamento)
        this.dataAgendamento = this.agendamento[0].data_agendamento;
        this.agendamento[0].data_agendamento = this.formatDate(this.agendamento[0].data_agendamento.date);
        this.agendamentoConfirmado = this.agendamento[0].confirmed;
        this.getServiceCategories();
      },
      (error) => {
        console.log(error);
        this.agendamentoCancelado = true;
      }
    );
    this.getData();
    
  } 


  getServiceCategories(){
    this.ngxLoaderSvc.start();
    this.servicoSvc.getAllServiceCategoriesLink01(this.company_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.serviceCategoryList = result;
        this.serviceCategoryList.forEach((sc) => {
          
          sc.serviceList.forEach((s) => {
            console.log('teste:', s.servico_id, this.agendamento[0].servico_id)
            if(s.servico_id == this.agendamento[0].servico_id){
              this.preco_mode = s.preco_mode;
              console.log(this.preco_mode)
              return
            }
          });
        });
        
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
        alert("Erro ao trazer serviços");
      }
    );
  }


  getData(){

    // TODO: check why getClientAgendamentoListConfirmaAgendamento is being called here

    this.futureAppointments = new Array<Agendamento>();
    this.previousAppointments = new Array<Agendamento>();

    this.ngxLoaderSvc.start();
    this.agSvc.getClientAgendamentoListConfirmaAgendamento().subscribe({
      next: (result) => {
        this.ngxLoaderSvc.stop();
        if(result != undefined){
          console.log(result)
          this.userName = result.settings[0].name;
          this.companyName = result.settings[0].nome_fantasia;
          this.tempoTolerancia = result.settings[0].tempo_cancelar_antecedencia*3600000;

          const timeNow = new Date().getTime();
          result.agendamento_list.forEach((a: Agendamento) => {

            const aDate = Utils.parseDBDateObjToJSDateObj(a.data_agendamento);
            aDate.setHours(Number.parseInt(a.time_start.substring(0, 2)), Number.parseInt(a.time_start.substring(3, 5)));

            if(aDate.getTime() < timeNow){
              this.previousAppointments.push(a);
            }
            else {
              this.futureAppointments.push(a);
            }
          });

        }
      },
      error: (error) => {
        this.ngxLoaderSvc.stop();
        console.log(error);
        alert('Ocorreu um erro em sua requisição. Por favor verifique sua conexão com a internet e tente mais tarde.');
      }
    });

  }

  formatDate(dateString) {
    var date = new Date(dateString);
    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    return day + '/' + month + '/' + year;
  }

  confirmar(){
    this.AgendamentoPublService.patchConfirmaAgendamento(this.id)
    .subscribe(
      (data) => {
        this.agendamentoConfirmado = true;
        this.globals.presentToast('Agendamento confirmado com sucesso!');
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cancelAppointment(){
    // Adicionando a confirmação
    if(!confirm("Tem certeza que deseja cancelar este agendamento?")) {
        return;
    }

    console.log(this.id)
    const aDate = Utils.parseDBDateObjToJSDateObj(this.dataAgendamento);
    aDate.setHours(Number.parseInt(this.agendamento[0].time_start.substring(0, 2)), Number.parseInt(this.agendamento[0].time_start.substring(3, 5)));

    const timeNow = new Date().getTime();

    if(timeNow > (aDate.getTime() - this.tempoTolerancia)){
      alert(`Não é possível cancelar este agendamento. Cancelamentos só podem ser feitos com ${this.tempoTolerancia/3600000} horas de antecedencia.`);
      return;
    }

    this.agStatusSvc.patch(this.id, 6).subscribe({
      next: (message) => {
        console.log(message);
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.ngxLoaderSvc.start();
    this.agSvc.deleteAgendamento(Number(this.id)).subscribe({
      next: (message) => {
        this.ngxLoaderSvc.stop();
        alert(message);
        this.agendamentoCancelado = true;
      },
      error: (error) => {
        this.ngxLoaderSvc.stop();
        console.log(error);
        alert('Ocorreu um erro em sua requisição. Por favor verifique sua conexão com a internet e tente mais tarde.');
      }
    });

}


}
