import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { CompanyUser } from '../company-user/CompanyUser';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class NewEmailService extends BaseService{

  baseURL = `${Config.vBusUrl}change_email_edp/`;
  constructor(
    private h: HttpClient
  ) {
    super(h);
  }

  getEmail(id) {
    return this.http.get<CompanyUser>(this.baseURL + `${id}/-1`)
    .pipe(
      map((data: any) => {
          //
        console.log('debug: data from getEmail = ', data);
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  patch(regObj: CompanyUser) {
    let obj = JSON.stringify(regObj);
    return this.http.patch(this.baseURL, obj)
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }
}
