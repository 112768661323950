<div class="page">
    <img src="../../assets/cartaoIcon.png" alt="logo" style="height: 100px; margin-bottom: 20px; margin-top: 20px;">

    <div class="card login-card">
        <div class="card-body">
            
            <form name="form1">

                <p>Cadastre-se!</p>

                <div class="form-group">
                    <label for="input1">Nome *</label>
                    <input type="text" class="form-control" [(ngModel)]="dtRow.name" maxlength="70" name="nome" id="input1" placeholder="Nome">
                </div>

                <div class="form-group">
                    <label for="birth1">Data de nascimento *</label>
                    <input type="numeric" class="form-control" mask="00/00/0000" [dropSpecialCharacters]="false" [(ngModel)]="birth_date" name="birth" id="birth1" placeholder="Data de nascimento">
                </div>

                <div class="form-group">
                    <label for="cpf1">CPF *</label>
                    <input type="numeric" class="form-control" mask="000.000.000-00" [dropSpecialCharacters]="false" [(ngModel)]="cpf" name="cpf" id="cpf1" placeholder="CPF">
                </div>
   
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Sexo *</label>
                    <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="dtRow.sex_id" name="sexo">
                        <option value="1">Feminino</option>
                        <option value="2">Masculino</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="phone1">Telefone *</label>
                    <input type="numeric" class="form-control" mask="(00) 00000-0000" [dropSpecialCharacters]="false" [(ngModel)]="dtRow.phone" name="fone" id="phone1" placeholder="Telefone">
                </div>

                <div class="form-group">
                    <label for="estado1">Estado *</label>
                    <select class="form-control" id="estado1" name="estado" [(ngModel)]="dtRow.state_id" (change)="selectState()" ngDefaultControl>
                        <option *ngFor="let st of stateLst" [ngValue]="st.state_id">{{st.name}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="cidade1">Pesquise e selecione a cidade *</label>
                    <input type="text" class="form-control" [(ngModel)]="cityStr" name="cidade" id="cidade1" (ngModelChange)="searchCity($event)">
                </div>

                <div class="list-group" *ngIf="!hideList">
                    <a class="list-group-item list-group-item-action" *ngFor="let ci of filteredCities" (click)="onCitySelected(ci)">{{ci.name}}</a>
                </div>

                <div class="form-group">
                    <label for="exampleInputEmail1">Email *</label>
                    <input type="email" class="form-control" [(ngModel)]="dtRow.email" maxlength="50" name="email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email">
                </div>

                <div class="form-group">
                    <label for="exampleInputPassword1">Senha *</label>
                    <input type="password" class="form-control" [(ngModel)]="pass1" name="senha" id="exampleInputPassword1" placeholder="Senha">
                </div>

                <div class="form-group">
                    <label for="exampleInputPassword1">Confirmar senha *</label>
                    <input type="password" class="form-control" [(ngModel)]="passConf" name="senhaConf" id="exampleInputPassword2" placeholder="Senha">
                </div>
    
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="dtRow.check" name="termos" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                        Concordo com os <a href="https://apiprod.loopingapp.com.br:7096/terms/termos_de_uso.pdf" target="blank">termos de uso</a>.
                    </label>
                </div>

                <div style="padding-top: 20px;">
                    <button type="button" class="btn btn-light" (click)="callRegister()">Cadastrar</button>
                </div>
    
            </form>

        </div>
        
    </div>

</div>


<ngx-ui-loader></ngx-ui-loader>