
export interface CompanyFreightConfigInterface {
  company_freight_config_id: number;
  company_id: number;
  free_freight_above: number;
  delivery_minimum_value: number;
}

export class CompanyFreightConfig implements CompanyFreightConfigInterface {
  company_freight_config_id: number;
  company_id: number;
  free_freight_above: number;
  delivery_minimum_value: number;
}