import { environment } from "src/environments/environment";

export class CadastroConfig {

    static prodFlag = environment.prodFlag;

    static prod = environment.cadastro_config_url; // 0
    // static dev = ""; // 6
    // static fernando = "http://192.168.1.100/cartao_fid_api/api/v_app/"; // 1
    // static calebe = ""; // 3
    // static calebeHome = ""; // 4
  
    static apiUrl = CadastroConfig.getCurrentApiAddrress();
  
    static getCurrentApiAddrress(): string {
        // if (CadastroConfig.prodFlag == 0) {
        //     return CadastroConfig.prod;
        // } else if (CadastroConfig.prodFlag == 1) {
        //     return CadastroConfig.fernando;
        // } else if (CadastroConfig.prodFlag == 2) {
        //     return CadastroConfig.samuel;
        // } else if (CadastroConfig.prodFlag == 3) {
        //   return CadastroConfig.calebe;
        // } else if (CadastroConfig.prodFlag == 4){
        //   return CadastroConfig.calebeHome;
        // } else if (CadastroConfig.prodFlag == 5) {
        //   return CadastroConfig.samuelHome;
        // } else if (CadastroConfig.prodFlag == 6) {
        //   return CadastroConfig.dev;
        // }
        return CadastroConfig.prod;
    }
}