import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoEsperaService extends BaseService {

  baseURL = `${Config.vWebUrl}agendamento_espera_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  salvarAgendamentoEspera(agendamentoEsperaObj){
    let obj = JSON.stringify(agendamentoEsperaObj);
    return this.http.post(this.baseURL, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      // timeout(10000),
      timeout(70000),
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

}
