import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { State } from './state';
import { BaseService2 } from '../baseservice2';
import { CadastroConfig } from '../cadastro_config';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class StateService extends BaseService2{
  baseURL = `${CadastroConfig.apiUrl}state_uf/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getRows() {
    return this.http.get<State>(this.baseURL + '-1', {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getById(id){
    return this.http.get<State>(this.baseURL + `${id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }
}
