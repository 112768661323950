

<div class="main-page2">

  <div id="whiteDiv" >

    <div class="planoBox ">

      <p class="planoTitle">{{plan.name}}</p>

      <p class="amountText">{{plan.amount | currency: 'BRL'}}</p>

    </div>

    <div class="container-fluid">
          
      <form [formGroup]="formPag">

        <div class="radio-input-div marginTopMedium" (click)="setPaymentCartao(true)">
          <img *ngIf="isPaymentCartao" src="assets/utilities/radio_button_checked.svg">
          <img *ngIf="!isPaymentCartao" src="assets/utilities/radio_button_unchecked.svg">
          <span>Adicione um cartão <span *ngIf="plan.installments">(em {{plan.installments}} vezes)</span> </span>
        </div>

        <div>
  
          <div *ngIf="isPaymentCartao">

            <div class="formGroup" *ngIf="plan.installments">
              <label for="parcelamentoField">Parcelamento</label>
              <input 
                id="parcelamentoField"
                type="text" 
                disabled
                inputmode="numeric" 
                [(ngModel)]="installmentString"
                [ngModelOptions]="{standalone: true}"
              >
            </div>

            <div class="formGroup">
              <label for="cardNumberField">Número do cartão *</label>
              <input 
                id="cardNumberField"
                type="text" 
                inputmode="numeric" 
                [brmasker]="{mask:'0000 0000 0000 0000 0000', len:24}" 
                formControlName="card_number"
              >
            </div>

            <div class="formGroup">
              <label for="cardHolderName">Nome do titular no cartão *</label>
              <input
                id="cardHolderName" 
                type="text" 
                formControlName="card_holder_name"
              >
            </div>

            <div class="ladoALado">

              <div class="formGroup">
                <label for="cardExpirationDate" class="labelLadoALado">Expiração do cartão (mm/AA) *</label>
                <input 
                  style="width: 100%;"
                  id="cardExpirationDate"
                  type="text" 
                  inputmode="numeric" 
                  [brmasker]="{mask:'00/00', len:5}" 
                  formControlName="card_expiration_date"
                >
              </div>

              <div class="formGroup">
                  <label for="card_cvv" class="labelLadoALado">Código de segurança *</label>
                  <input 
                    style="width: 100%;"
                    id="card_cvv" 
                    type="text" 
                    inputmode="numeric" 
                    [brmasker]="{mask:'0000', len:4}" 
                    formControlName="card_cvv"
                  >
              </div>
            </div>

          </div>

          <div class="formGroup">
              <label for="email">Email *</label>
              <input 
                id="email" 
                type="text" 
                inputmode="email" 
                formControlName="email"
              >
          </div>

          <!-- <div class="cadastreLabelDiv">
              <label class="cadastreseLabel" *ngIf="isPaymentCartao">Dados extras:</label>
              <label class="cadastreseLabel" *ngIf="!isPaymentCartao">Dados para gerar o boleto:</label>
          </div> -->

          <div class="formGroup">
              <label for="fullName">Nome completo ou Razão Social *</label>
              <input 
                id="fullName" 
                type="text" 
                formControlName="name"
              >
          </div>

          <div class="formGroup" *ngIf="isCPF">
              <label for="cpfInput">CPF/CNPJ do titular *</label>
              <input 
                id="cpfInput" 
                type="text" 
                inputmode="numeric" 
                maxlength="15"
                [brmasker]="{mask: '000.000.000-00', type:'num'}" 
                formControlName="document_number"
              >
          </div>

          <div class="formGroup" *ngIf="!isCPF">
              <label for="cpfInput">CPF/CNPJ do titular *</label>
              <input 
                id="cnpjField" 
                type="text" 
                inputmode="numeric" 
                maxlength="18"
                [brmasker]="{mask: '00.000.000/0000-00', type:'num'}" 
                formControlName="document_number"
              >
          </div>

          <div class="ladoALado">
            <div class="formGroup" style="width: 70%;">
                <label for="streetField" style="width: 100%;">Rua *</label>
                <input
                  style="width: 100%;"
                  id="streetField" 
                  type="text" 
                  formControlName="street"
                >
            </div>

            <div class="formGroup" style="width: 30%;">
                <label for="numberField" style="width: 100%;">Número *</label>
                <input
                  style="width: 100%;"
                  id="numberField" 
                  type="text" 
                  formControlName="street_number"
                >
            </div>
          </div>

          <!-- <div class="formGroup">
              <label for="complementary">Complemento</label>
              <input 
                id="complementary" 
                type="text" 
                formControlName="complementary"
              >
          </div> -->

          <div class="formGroup">
              <label for="neighborhood">Bairro *</label>
              <input 
                id="neighborhood" 
                type="text" 
                formControlName="neighborhood"
              >
          </div>

          <div class="formGroup">
              <label for="zipcode">CEP *</label>
              <input 
                id="zipcode" 
                type="text" 
                formControlName="zipcode" 
                inputmode="numeric" 
                [brmasker]="{mask:'00000-000', len:9}"
              >
          </div>
          <div class="ladoALado">
          <div class="formGroup" style="width: 30%;">
              <label for="phoneNumberDdd">DDD do telefone *</label>
              <input
                id="phoneNumberDdd"
                placeholder="XX"
                type="text"
                inputmode="numeric"
                [brmasker]="{mask:'00', len:2}" 
                formControlName="ddd"
              >
          </div>

          <div class="formGroup">
              <label for="phoneNumber">Número de telefone (sem DDD) *</label>
              <input 
                id="phoneNumber"
                placeholder="XXXXX-XXXX" 
                type="tel" 
                inputmode="tel"
                maxlength="9"
                formControlName="number"
              >
          </div>
        </div>

          <div class="radio-input-div" (click)="setPaymentCartao(false)">
            <img *ngIf="!isPaymentCartao" src="assets/utilities/radio_button_checked.svg">
            <img *ngIf="isPaymentCartao" src="assets/utilities/radio_button_unchecked.svg">
            <span>Pagar com boleto</span>
          </div>

          <div class="col marginVerticalSmall ">

            <div class="d-flex flex-row align-items-center">

              <div class="d-flex flex-column" style="width: 70%;">
                <label>Solucione o captcha abaixo para continuar</label>
                <img class="recaptchaImg" src="{{recaptchaImgSrc}}" alt="recaptcha">
              </div>

              <div>
                <button class="buttonStyle marginHorizontalSmall" (click)="getRecaptcha()">Recarregar imagem</button>
              </div>
            </div>

            <div class="formGroup">
              <input type="text" formControlName="recaptchaSolution">
            </div>
            
          </div>


          <div class="cadastreLabelDivCent">
              <button class="btnResgate largeBtn" style="background-color: #0d474a;" (click)="tryContratar()">Assinar plano</button>
          </div>
  
        </div>
      </form>

      <div class="card marginVerticalMedium" style="width: 90%;">
        <div style="height: 100%;">
            
          <div>
            <label class="alignTxtCenter">Essa é uma compra segura efetuada através do Pagar.me</label>
          </div>
  
          <div>
            <img src="assets/pagarmelogo.svg" />
          </div>
    
        </div>
      </div>

    </div>

  </div>

</div>