import { Injectable } from '@angular/core';
import { Company } from 'src/app/svc/company/company';
import { DeliveryMethod } from 'src/app/svc/company/delivery/deliveryMethod';
import { CompanyFreightConfig } from 'src/app/svc/company/frete-bairro/company-freight-config';
import { FreteBaseWeb } from 'src/app/svc/frete/frete-base-web';
import { Workhour } from 'src/app/svc/workhour/workhour';

@Injectable({
  providedIn: 'root'
})
export class CompanyDataHandler {

  private _companyWorkhours: Workhour[];
  private _companyData: Company;
  private _deliveryTime: string;
  private _frete: FreteBaseWeb;
  private _deliveryMethods: DeliveryMethod[];
  private _retornoDoFrete: any;
  private _propsQueried: boolean = false;

  constructor(){
    this._companyWorkhours = [];
    this._companyData = new Company();
    this._deliveryMethods = [];
    this.deliveryTime = '-';
    this._frete = new FreteBaseWeb();
    this._retornoDoFrete = {};
  }

  public get companyWorkhours() {
    return this._companyWorkhours;
  }

  public set companyWorkhours(value) {
    this._companyWorkhours = value;
  }

  public get companyData() {
    return this._companyData;
  }
  
  public set companyData(value) {
    this._companyData = value;
  }

  public get deliveryTime() {
    return this._deliveryTime;
  }
  
  public set deliveryTime(value) {
    this._deliveryTime = value;
  }

  public get frete() {
    return this._frete;
  }

  public set frete(value) {
    this._frete = value;
    if (!this._frete.company_freight_config || !this._frete.company_freight_config.company_freight_config_id) {
      this._frete.company_freight_config = new CompanyFreightConfig();
    }
  }

  public checkTodayWorkhour() {

    let shopStatus = false;
    let closeOpenTime = '';

    const dateNow = new Date();

    let adjustedDay: number;
    if (dateNow.getDay() === 0) {
      adjustedDay = 7;
    } else {
      adjustedDay = dateNow.getDay();
    }

    const todayWhs = this._companyWorkhours.filter(wh => {
      return wh.week_day_id == adjustedDay;
    });

    todayWhs.forEach(todayWorkhour =>{

      if(shopStatus == true){
        return;
      }

      // tslint:disable: radix
      let viraDia = false;
      
      const openHourToday = Number.parseInt(todayWorkhour.open_time.substr(0, 2));
      const openMinToday = Number.parseInt(todayWorkhour.open_time.substr(3, 2));
      const closeHourToday = Number.parseInt(todayWorkhour.close_time.substr(0, 2));
      const closeMinTpday = Number.parseInt(todayWorkhour.close_time.substr(3, 2));

      if (openHourToday > closeHourToday) {
        viraDia = true;
      } else if (openHourToday == closeHourToday) {
        if (openMinToday > closeMinTpday) {
          viraDia = true;
        }
      }
      if (openHourToday < dateNow.getHours()
        && closeHourToday > dateNow.getHours()) {
        shopStatus = true;
      } else if (openHourToday === dateNow.getHours()
        && openMinToday < dateNow.getMinutes()
        && closeHourToday > dateNow.getHours()) {
        shopStatus = true;
      } else if (closeHourToday === dateNow.getHours()
        && closeMinTpday > dateNow.getMinutes()) {
        shopStatus = true;
      } else {
        shopStatus = false;
      }
      if (shopStatus) {
        closeOpenTime = todayWorkhour.close_time;
      } else {
        if (openHourToday > dateNow.getHours()) {
          closeOpenTime = todayWorkhour.open_time;
        } else if (openHourToday === dateNow.getHours()
          && openMinToday > dateNow.getMinutes()) {
          closeOpenTime = todayWorkhour.open_time;
        } else {
          closeOpenTime = null;
        }
        if (openHourToday === closeHourToday
          && openMinToday === closeMinTpday) {
          closeOpenTime = null;
        }
      }

      if (!shopStatus) {
        
        let yesterdayId: number;
        if (adjustedDay == 1) {
          yesterdayId = 7;
        } else {
          yesterdayId = adjustedDay - 1;
        }

        const yesterdayWhs = this._companyWorkhours.filter(wh => {
          return wh.week_day_id == yesterdayId;
        });

        yesterdayWhs.forEach(yesterday => {

          const openHourYesterday = Number.parseInt(yesterday.open_time.substr(0, 2));
          const openMinYesterday = Number.parseInt(yesterday.open_time.substr(3, 2));
          const closeHourYesterday = Number.parseInt(yesterday.close_time.substr(0, 2));
          const closeMinYesterday = Number.parseInt(yesterday.close_time.substr(3, 2));

          if (closeHourToday < openHourToday
            && dateNow.getHours() > openHourToday) {
            shopStatus = true;
            closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourToday == openHourToday
            && closeMinTpday < openMinToday
            && dateNow.getHours() > openHourToday) {
            shopStatus = true;
            closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourToday < openHourToday
            && dateNow.getHours() == openHourToday
            && dateNow.getMinutes() > openMinToday) {
            shopStatus = true;
            closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourToday == openHourToday
            && closeMinTpday < openMinToday
            && dateNow.getHours() == openHourToday
            && dateNow.getMinutes() > openMinToday) {
            shopStatus = true;
            closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourYesterday < openHourYesterday
            && dateNow.getHours() < closeHourYesterday) {
            shopStatus = true;
            closeOpenTime = yesterday.close_time;
          } else if (closeHourYesterday == openHourYesterday
            && closeMinYesterday < openMinYesterday
            && dateNow.getHours() < closeHourYesterday) {
            shopStatus = true;
            closeOpenTime = yesterday.close_time;
          } else if (closeHourYesterday < openHourYesterday
            && dateNow.getHours() == closeHourYesterday
            && dateNow.getMinutes() < closeMinYesterday) {
            shopStatus = true;
            closeOpenTime = yesterday.close_time;
          } else if (closeHourYesterday == openHourYesterday
            && closeMinYesterday < openMinYesterday
            && dateNow.getHours() == closeHourYesterday
            && dateNow.getMinutes() < closeMinYesterday) {
            shopStatus = true;
            closeOpenTime = yesterday.close_time;
          }

        });
      }
      
    });

    if(shopStatus == false && todayWhs.length > 1){

      const hour = dateNow.getHours();
      const min = dateNow.getMinutes();

      const whAux = todayWhs.filter(wh => {

        return Number.parseInt(wh.open_time.substr(0, 2)) > hour 
            || (Number.parseInt(wh.open_time.substr(0, 2)) == hour && Number.parseInt(wh.open_time.substr(3, 2)) >= min)

      });

      if(whAux.length == 0){
        closeOpenTime = null;
      }
      else if(whAux.length == 1){
        closeOpenTime = whAux[0].open_time;
      }
      else {
        if(whAux[0].open_time < whAux[1].open_time){
          closeOpenTime = whAux[0].open_time;
        }
        else{
          closeOpenTime = whAux[1].open_time;
        }
      }

    }

    return { shopStatus: shopStatus, closeOpenTime: closeOpenTime };

  }

  public get deliveryMethods(): DeliveryMethod[] {
    return this._deliveryMethods;
  }
  public set deliveryMethods(value: DeliveryMethod[]) {
    this._deliveryMethods = value;
  }

  public get retornoDoFrete(): any {
    return this._retornoDoFrete;
  }

  public set retornoDoFrete(value: any) {
    this._retornoDoFrete = value;
  }

  public get propsQueried(): boolean {
    return this._propsQueried;
  }

  public set propsQueried(value: boolean) {
    this._propsQueried = value;
  }

}