
export class PaywallContent {
  head1: string;
  description: string;
  listaFunc: string[];
  imagePath: string;

  constructor() {
    this.listaFunc = [];
  }
}