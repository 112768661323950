import { Component, OnInit } from '@angular/core';
import { Globals } from '../../svc/globals';
import { PlanService } from '../../svc/company/plan/plan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AssinaturaService } from '../../svc/company/plan/assinatura.service';
import { Plan } from '../../svc/company/plan/plan';
import { FormAssinatura } from '../../svc/company/plan/FormAssinatura';
import { CompanyUtils } from '../../svc/company/company-utils';

@Component({
  selector: 'app-assin-actions',
  templateUrl: './assin-actions.component.html',
  styleUrls: ['./assin-actions.component.scss']
})
export class AssinActionsComponent implements OnInit {

  isLoading: boolean;
  act: number;
  pid: number;
  newPlan: Plan;

  constructor(public globals: Globals, 
    private planSvc: PlanService,
    private route: ActivatedRoute,
    private router: Router,
    private assinSvc: AssinaturaService) { }

  ngOnInit() {
    this.globals.headerTitle = '';

    // if(this.route.snapshot.routeConfig.path.includes('agenda')){
    //   this.globals.backRoute = 'ambiente/agenda/usage-plans';
    // }
    // else{
    //   this.globals.backRoute = 'ambiente/catalogo/usage-plans';
    // }
    this.globals.backRoute = 'ambiente/agenda/usage-plans';

    // get route components
    this.act = parseInt(this.route.snapshot.paramMap.get('act'));
    this.pid = parseInt(this.route.snapshot.paramMap.get('pid'));

    // act: 1 is cancellation, 2 is upgrade or downgrade of plan
    if (this.act == 1) {
      this.globals.headerTitle = 'Cancelar WhatsAgenda';
      
    } else if (this.act == 2) {
      this.globals.headerTitle = 'Mudar plano';
    }
    this.newPlan = new Plan();  // newPlan is used both for cancel or update
    this.getPlanInfo();
  }

  getPlanInfo(){
    this.planSvc.getPlanId(this.pid).subscribe(
      (result) => {
        this.newPlan = result[0];
      },
      (err) => {
        this.globals.openDialog("Erro", "Não foi possível trazer as informações do plano. Tente novamente");
      }
    );
  }

  tryChanges(){
    this.alertaConfNeg("Atenção", "Você está prestes a mudar o seu pacote de assinaturas do WhatsAgenda", "Ok, concordo", 1)
  }

  runContractActions(){
    let pObj = new FormAssinatura();
    pObj.company_id = CompanyUtils.getCompanyId();
    pObj.plan_id = this.newPlan.plan_id; // plan_id is the ID on Looping Database
    pObj.gateway_id = this.newPlan.gateway_id; // gateway_id is the ID on Pagar.Me

    this.assinSvc.changeAssinatura(pObj).subscribe(
      (result) => {
        //
        this.globals.openDialog("Sucesso", "Plano alterado com sucesso");

        // if(this.route.snapshot.routeConfig.path.includes('agenda')){
        //   this.router.navigate(['/ambiente/agenda/usage-plans']);
        // }
        // else{
        //   this.router.navigate(['/ambiente/catalogo/usage-plans']);
        // }
        this.router.navigate(['/ambiente/agenda/usage-plans']);
      },
      (err) => {
        console.log(err)
        this.globals.openDialog("Erro", "Erro ao mudar seu plano");
      }
    );
  }

  tryCancelation(){
    this.alertaConfNeg("Atenção", "Você está prestes a CANCELAR sua assinatura do WhatsAgenda", "Ok", 2);
  }

  runCancellation(){
    let pObj = new FormAssinatura();
    pObj.company_id = CompanyUtils.getCompanyId();
    pObj.plan_id = this.newPlan.plan_id; // plan_id is the ID on Looping Database
    pObj.gateway_id = this.newPlan.gateway_id; // gateway_id is the ID on Pagar.Me

    this.assinSvc.cancelAssinatura(pObj).subscribe(
      (result) => {
        //
        this.globals.openDialog("Sucesso", "Cancelamento efetuado");
        
        // if(this.route.snapshot.routeConfig.path.includes('agenda')){
        //   this.router.navigate(['/ambiente/agenda/usage-plans']);
        // }
        // else{
        //   this.router.navigate(['/ambiente/catalogo/usage-plans']);
        // }
        this.router.navigate(['/ambiente/agenda/usage-plans']);
      },
      (err) => {
        console.log(err)
        this.globals.openDialog("Erro", err.error.message);
      }
    );
  }
  
  alertaConfNeg(pTitle, pMessage, pBtnText, handlerDest){

    this.globals.openConfirm(pMessage, () => {
      if (handlerDest == 0) {
        return;
      } else if (handlerDest == 1) {
        this.runContractActions();
      } else if (handlerDest == 2) {
        this.runCancellation();
      } else {
        return;
      }
    });
  }

}
