import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoPublService extends BaseService {

  baseURL = `${Config.vWebUrl}agendamento_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAllForClient(company_id, dataIni){
    return this.http.get(this.baseURL + `-1/${company_id}/${dataIni}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  salvarAgendamento(agendamento_array, company_id){
    const obj = JSON.stringify({company_id: company_id, agendamento_array: agendamento_array});
    return this.http.post(this.baseURL, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      // timeout(10000),
      timeout(70000),
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data;
      }),
      catchError(this.handleErrors)
    );
  }

  getByAgendamentoId(agendamento_id){
    return this.http.get(this.baseURL + '-2/' + agendamento_id, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  patchConfirmaAgendamento(agendamento_id) {
    const requestBody = {
      agendamento_id: agendamento_id
    };
  
    return this.http.patch(this.baseURL, requestBody, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      catchError(this.handleErrors)
    );
  }
  


}
