import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../config';
import { BaseService } from '../base.service';
import { ProductCategory } from './product-category';
import { Product } from './product';
import { CompanyUtils } from '../company/company-utils';
import { ProductOptionName } from './productOptionName';
import { ProductOptionValue } from './productOptionValue';
import { ProductAditionalCateg } from './productAditionalCateg';
import { ProductAditional } from './productAditional';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {
  baseURL = `${Config.vWebUrl}produtos_edp/`;
  prodPdvURL = `${Config.vBusUrl}pdv_products/`;

  menuLst: ProductCategory[];

  constructor(private h: HttpClient) {
    super(h);
    this.menuLst = new Array<ProductCategory>();
  }

  getMenuLst() {
    return this.menuLst;
  }

  setMenuLst(pMenu: Array<ProductCategory>) {
    this.menuLst = pMenu;
  }

  // getToClientByCompanyId(handle) {
  //   return this.http.get<ProductCategory>(this.baseURL + `${handle}/0/-1`, {
  //     headers: this.getCommonHeaders(false)
  //   })
  //     .pipe(
  //       map((data: any) => {
  //         // console.log('Debug: data = ', data);
  //         if (data.status != "200") {
  //           catchError(this.handleErrors);
  //         }
  //         return data.result;
  //       }),
  //       catchError(this.handleErrors)
  //     );
  // }

  getToClientByCompanyId02(handle) {
    return this.http.get<ProductCategory>(this.baseURL + `${handle}/0/-3`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getOptsAndAdtsByProdId(product_id) {
    return this.http.get<ProductCategory>(this.baseURL + `${product_id}/-1`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
      .pipe(
        map((data: any) => {
          // console.log('Debug: data = ', data);
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data.result;
        }),
        catchError(this.handleErrors)
      );
  }

  getForPdv(requestType: string){
    const httpParams = new HttpParams({
      fromObject: {
        requestType: requestType,
        company_id: CompanyUtils.getCompanyId(),
      }
    });
    return this.http.get<Product>(this.prodPdvURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false),
      params: httpParams,
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getProductDetailsForPdv(requestType: string, product_id: number) {
    const httpParams = new HttpParams({
      fromObject: {
        requestType: requestType,
        company_id: CompanyUtils.getCompanyId(),
        product_id: product_id,
      }
    });
    return this.http.get<Product>(`${this.prodPdvURL}${product_id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false),
      params: httpParams,
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  processDetailsQueryResult(result, selectedProductParam: Product) {
    if (result.productAditionals && result.productAditionals.length > 0){
      selectedProductParam.hasAditional = true;
    } else {
      selectedProductParam.hasAditional = false;
      result.productAditionals = [];
    }

    // prod can only have aditional or variant
    if (result.productAditionals && result.productAditionals.length == 0){
      selectedProductParam.variants = result.variants ? result.variants : [];
      if (selectedProductParam.variants.length > 0){
        selectedProductParam.hasVariant = true;
      } else {
        selectedProductParam.hasVariant = false;
      }
    } else {
      selectedProductParam.hasVariant = false;
      selectedProductParam.variants = [];
    }


    if (selectedProductParam.hasVariant) {
      selectedProductParam.variants.forEach(vari => {
        vari.str = '';
        vari.order_quant = 0;
        if (vari.var_size != null && vari.var_size != '') {
          vari.str = vari.str.concat(`Tamanho: ${vari.var_size}; `);
        }
        if (vari.color != null && vari.color != '') {
          vari.str = vari.str.concat(`Cor: ${vari.color}; `);
        }
      });

    } else {
      selectedProductParam.order_quant = 1;

      const prodOpts = new Array<ProductOptionName>();
      if (result.productOptions) {
        result.productOptions.forEach((elem) => {
          let added = false;
          prodOpts.forEach((opt) => {
            if (opt.product_option_name_id.toString().match(elem.optId)) {
              added = true;
              const newValue = new ProductOptionValue();
              newValue.product_option_value_id = elem.product_option_value_id;
              newValue.name = elem.valueName;
              newValue.price = (elem.price > 0) ? (elem.price * 1) : 0;
              // newValue.priceStr = (newValue.price > 0) ? (" " + this.companyDataHandler.companyData.currency_symbol + ' ' + newValue.price.toFixed(2).replace('.', ',')) : "";
              newValue.description = elem.description;
              newValue.addedToCart = false;
              newValue.list_position = Number.parseInt(elem.value_position);
              opt.values.push(newValue);
            }
          });

          if (!added) {
            const newOpt = new ProductOptionName();
            newOpt.product_option_name_id = elem.optId;
            newOpt.name = elem.optName;
            newOpt.is_greater_price = (elem.is_greater_price == "0") ? false : true;
            newOpt.considers_medium_price = (elem.considers_medium_price == "0" ? false : true);
            newOpt.list_position = Number.parseInt(elem.name_position);
            newOpt.values = [];

            const newValue = new ProductOptionValue();
            newValue.product_option_value_id = elem.product_option_value_id;
            newValue.name = elem.valueName;
            newValue.price = (elem.price > 0) ? (elem.price * 1) : 0;
            // newValue.priceStr = (newValue.price > 0) ? (" " + this.companyDataHandler.companyData.currency_symbol + ' ' + newValue.price.toFixed(2).replace('.', ',')) : "";
            newValue.addedToCart = false;
            newValue.description = elem.description;
            newValue.list_position = Number.parseInt(elem.value_position);
            newOpt.values.push(newValue);

            prodOpts.push(newOpt);
          }
        });
      }

      const prodAdts = new Array<ProductAditionalCateg>();
      result.productAditionals.forEach((elem) => {
        let added = false;
        prodAdts.forEach((adt) => {
          const elemAdtId = elem.adtId.toString();
          if ( adt.product_aditional_categ_id.toString() === elemAdtId ) {
            added = true;
            const newValue = new ProductAditional();
            newValue.product_aditional_id = elem.product_aditional_id;
            newValue.name = elem.valueName;
            newValue.price = (elem.price > 0) ? (elem.price * 1) : 0;
            // newValue.priceStr = (newValue.price > 0) ? (this.companyDataHandler.companyData.currency_symbol + ' ' + newValue.price.toFixed(2).replace('.', ',')) : "";
            newValue.added = false;
            newValue.list_position = Number.parseInt(elem.value_position);
            newValue.description = elem.description;
            newValue.max_per_product = elem.valueMax;
            adt.values.push(newValue);
          }
        });

        if (!added) {
          const newAdt = new ProductAditionalCateg();
          newAdt.product_aditional_categ_id = elem.adtId;
          newAdt.name = elem.adtName;
          newAdt.max_per_product = elem.max_per_product;
          newAdt.list_position = Number.parseInt(elem.categ_position);
          newAdt.values = [];

          const newValue = new ProductAditional();
          newValue.product_aditional_id = elem.product_aditional_id;
          newValue.name = elem.valueName;
          newValue.price = (elem.price > 0) ? (elem.price * 1) : 0;
          // newValue.priceStr = (newValue.price > 0) ? (this.companyDataHandler.companyData.currency_symbol + ' ' + newValue.price.toFixed(2).replace('.', ',')) : "";
          newValue.added = false;
          newValue.list_position = Number.parseInt(elem.value_position);
          newValue.description = elem.description;
          newValue.max_per_product = elem.valueMax;
          newAdt.values.push(newValue);

          prodAdts.push(newAdt);
        }
      });

      prodOpts.sort((a, b) => {
        if(a.list_position < b.list_position){
          return -1;
        }
        else if( a.list_position > b.list_position){
          return 1;
        }
        else {
          return 0;
        }
      });

      prodOpts.forEach(pon => {
        pon.values.sort((a, b) => {
          if(a.list_position < b.list_position){
            return -1;
          }
          else if( a.list_position > b.list_position){
            return 1;
          }
          else {
            return 0;
          }
        });
      });

      prodAdts.sort((a, b) => {
        if(a.list_position < b.list_position){
          return -1;
        }
        else if( a.list_position > b.list_position){
          return 1;
        }
        else {
          return 0;
        }
      });

      prodAdts.forEach(pac => {
        pac.values.sort((a, b) => {
          if(a.list_position < b.list_position){
            return -1;
          }
          else if( a.list_position > b.list_position){
            return 1;
          }
          else {
            return 0;
          }
        });
      });

      selectedProductParam.productOptions = prodOpts;
      selectedProductParam.productAditionals = prodAdts;
      ////////////////////////////////////////
      // this.resetSelectedProduct();
      // this.computeSelectedSubtotal();
    }

    return selectedProductParam;
  }

}
