import { Component, OnDestroy, OnInit } from '@angular/core';
import { Globals } from '../svc/globals';
import { trigger, style, animate, transition } from '@angular/animations';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Company } from '../svc/company/company';
import { CompanyUtils } from '../svc/company/company-utils';
import { Subscription } from 'rxjs';
// import { WorkhourService2 } from '../svc/company/workhour/workhour2.service';
import { ProfileService } from 'src/app/svc/company/company-user/profile.service';
import { AgendamentoService } from '../svc/company/agendamento/agendamento.service';
import { take } from 'rxjs/operators';
import { RootStylesService } from '../svc/root-styles/root-styles.service';
import { set } from 'date-fns';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompanyService } from '../svc/company/company.service';
// import { AssinaturaService } from '../svc/company/plan/assinatura.service';
import { CashRegisterService } from '../../../src/app/services/pdv-cash-register/cash-register.service';
import { PermissionsService } from '../svc/company/permissions/permissions.service';

@Component({
  selector: 'app-ambiente-agenda',
  templateUrl: './ambiente-agenda.component.html',
  styleUrls: ['./ambiente-agenda.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.25s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.25s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'slideAnimation',
      [
        transition(
          ':enter',
          [
            style({ left: '-70%' }),
            animate('0.25s ease-out',
              style({ left: '0' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ left: '0' }),
            animate('0.25s ease-in',
              style({ left: '-70%' }))
          ]
        )
      ]
    ),
    trigger(
      'refreshPromptAnimation',
      [
        transition(
          ':enter',
          [
            style({ right: '-20rem' }),
            animate('0.25s ease-out',
              style({ right: '1%' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ right: '1%' }),
            animate('0.25s ease-in',
              style({ right: '-20rem' }))
          ]
        )
      ]
    )
  ]
})
export class AmbienteAgendaComponent implements OnInit, OnDestroy {

  showConfigutarions = false;
  showAtalhos = false;
  showProducts = false;
  showTickets = false;
  showPedidos = false;
  showCartoes = false;
  showClientes = false;
  showFrete = false;
  showSuporte = false;
  company: Company
  cash = 0;
  selectedButton: number | null = null;

  private routerEventSub$: Subscription;
  triggerBool: boolean = true;   // just to trigger the reload of subscription feedback

  shopStatus: boolean;

  linkCopied = false;

  isAdmin = false;
  nomeCompany: string;

  alarmTimeOut;
  agendaCountTimeout;
  elementsHidden: boolean;

  isCashRegisterOpen: boolean;

  currentTheme: string = 'theme-default';
  company_id = CompanyUtils.getCompanyId();

  //permissoes do usuario
  visualizarAgenda = false;
  editarAgenda = false;
  historicoAgenda = false;
  financeiro = false;
  relatoriosFaturamento = false;
  listaEspera = false;
  clientes = false;
  gerenciarFuncionarios = false;
  gerenciarRecessos = false;
  planosAssinaturas = false;
  controledecaixa = false;
  comandas = false;
  produtoseestoque = false;

  assinaturaFim: string;
  companyPaymentStatus: string;


  constructor(
    public globals: Globals,
    public router: Router,
    // private whSvc: WorkhourService2,
    private compSvc: ProfileService,
    private agendamentoSvc: AgendamentoService,
    private rootStylesService: RootStylesService,
    private ngxLoaderSvc: NgxUiLoaderService,
    private companySvc: CompanyService,
    // private assinaturaService: AssinaturaService,
    private cashRegisterService: CashRegisterService,
    private permissions: PermissionsService,
  ) {
    this.company = new Company();
  }

  ngOnInit() {
    
    
    this.getPermissions();
    const savedSelection = localStorage.getItem('selectedButton');
    if (savedSelection !== null) {
        this.selectedButton = Number(savedSelection);
    }

    this.cashRegisterService.currentCashRegisterState.subscribe(isOpen => {
      this.isCashRegisterOpen = isOpen;
    });
    console.log(this.isCashRegisterOpen);

    setTimeout(() => {
      this.companyPaymentStatus = localStorage.getItem('companyStatusPayment');
      const assinaturaFim = localStorage.getItem('currentEnd');

    if (assinaturaFim) {
      const data = new Date(assinaturaFim);
      const dia = data.getDate().toString().padStart(2, '0');
      const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0!
      const ano = data.getFullYear();
      this.assinaturaFim = `${dia}/${mes}/${ano}`;
      }
    },1000);
    // console.log( this.companyPaymentStatus);

    this.isAdmin = CompanyUtils.getUserType() != 2;

    if (window.screen.width < 1000) {
      document.getElementById("sidebar").style.left = "-100%";
      document.getElementById("sidebar").style.width = "100vw";
      document.getElementById("sidebar").style.minWidth = "100vw";
      document.getElementById("sidebar").style.zIndex = "1";
      document.getElementById("sidebar").style.position = "absolute";
      document.getElementById("pageContent").style.width = "100vw";
      this.globals.showMenuBtn = true;
    }
    else{
      this.globals.showMenuBtn = false;
    }

    this.company.img_cartao_link = CompanyUtils.getImgCartaoLink();
    this.company.nome_fantasia = CompanyUtils.getCompanyName();
    this.nomeCompany = this.company.nome_fantasia;
    this.globals.playAlarm = CompanyUtils.getAlarmPreference();
    //permissoes do usuario
    // this.visualizarAgenda = CompanyUtils.canVisulizarAgenda();
    // this.editarAgenda = CompanyUtils.canEditarAgenda();
    // this.historicoAgenda = CompanyUtils.canHistoricoAgenda();
    // this.financeiro = CompanyUtils.canFinanceiro();
    // this.relatoriosFaturamento = CompanyUtils.canRelatoriosFaturamento();
    // this.listaEspera = CompanyUtils.canListaEspera();
    // this.clientes = CompanyUtils.canClientes();
    // this.gerenciarFuncionarios = CompanyUtils.canGerenciarFuncionarios();
    // this.gerenciarRecessos = CompanyUtils.canGerenciarRecessos();
    // this.planosAssinaturas = CompanyUtils.canPlanosAssinaturas();
    // this.controledecaixa = CompanyUtils.canControleDeCaixa();
    // this.comandas = CompanyUtils.canComandas();
    // this.produtoseestoque = CompanyUtils.canProdutosEEstoque();

    this.routerEventSub$ = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd){
        const currentRoute = this.router.url;
        if (currentRoute === '/ambiente/agenda/agendamento-list'){
          // refresh app subscription feedback component
          this.triggerBool = !this.triggerBool;
        }
      }
    });

    // this.getWorkhours();
    this.getLink();

    if(this.globals.checkAgenda){
      this.getAgendaCount(this.agendamentoSvc, this.globals, this.alarmTimeOut, this.agendaCountTimeout, this.loopAlarm);
    }

    this.setRootStyles();
    this.getCompanyData();
  }

  ngOnDestroy(): void {
    this.routerEventSub$.unsubscribe();

    clearTimeout(this.alarmTimeOut);
  }

  getCompanyData() {
    // will return whatsapp_phone and company data
    this.ngxLoaderSvc.start();
    this.companySvc.getAgendamentoCompData(this.company_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.currentTheme = !result.linkThemeName ? 'theme-default' : result.linkThemeName;
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
      }
    );
  }

  getPermissions(): void {
    this.permissions.getUserPermissions(CompanyUtils.getUserId()).pipe(take(1)).subscribe({
      next: (result) => {
        // console.log('===', result);
        CompanyUtils.setUserAccess(result[0].access_ids);
        this.visualizarAgenda = CompanyUtils.canVisulizarAgenda();
        this.editarAgenda = CompanyUtils.canEditarAgenda();
        this.historicoAgenda = CompanyUtils.canHistoricoAgenda();
        this.financeiro = CompanyUtils.canFinanceiro();
        this.relatoriosFaturamento = CompanyUtils.canRelatoriosFaturamento();
        this.listaEspera = CompanyUtils.canListaEspera();
        this.clientes = CompanyUtils.canClientes();
        this.gerenciarFuncionarios = CompanyUtils.canGerenciarFuncionarios();
        this.gerenciarRecessos = CompanyUtils.canGerenciarRecessos();
        this.planosAssinaturas = CompanyUtils.canPlanosAssinaturas();
        this.controledecaixa = CompanyUtils.canControleDeCaixa();
        this.comandas = CompanyUtils.canComandas();
        this.produtoseestoque = CompanyUtils.canProdutosEEstoque();
      },
      error: (error) => {
        console.log('error getting permissions', error);
      },
    });
  }

  setRootStyles() {
    const styleObj = CompanyUtils.getPageTheme();
    this.rootStylesService.setRootStyles(styleObj);
  }

  goToCompanyProfile() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/user-profile');
  }

  // goToCompanyworkhours() {
  //   this.router.navigateByUrl('ambiente/agenda/workhours');
  // }

  toggleElements(): void {
    const collapsibleElements = document.querySelectorAll('.collapsible');
    collapsibleElements.forEach(el => {
      el.classList.toggle('collapsed');
    });

    this.elementsHidden = !this.elementsHidden;
  }


  goToUsagePlans() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/usage-plans');
  }

  goToNewPassword() {
    this.router.navigateByUrl('ambiente/agenda/new-password');
  }

  goToPrincipalClients() {
    this.router.navigateByUrl('ambiente/agenda/top-clients');
  }

  goToEditLogin() {
    this.router.navigateByUrl('ambiente/agenda/edit-login');

  }

  goToMeuLink() {
    this.router.navigateByUrl('ambiente/agenda/meu-link');

  }

  goToClubeAssinaturas() {
    this.router.navigateByUrl('ambiente/agenda/clube-de-assinaturas');
  }

  goToDashboard() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/dashboard');
  }

  exitUser() {
    localStorage.clear();
    this.globals.companyNoPay = false;
    this.router.navigateByUrl('agenda/login');

  }

  goToLinkTheme() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/link-theme');
  }

  goToClientes() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/clients/1');
  }

  goToHome() {
    this.router.navigateByUrl('ambiente/agenda/');
  }

  goToCaixa() {
    this.closeSideBar();
  }

  goToComandas() {
    this.closeSideBar();
  }

  goToProdutos() {
    this.closeSideBar();
  }

  goToAgendamentoCadastro(tab?) {
    this.closeSideBar();
    if (tab){
      this.router.navigate(['/ambiente/agenda/agendamento-cadastro/', tab]);
      return;
    }
    this.router.navigateByUrl('ambiente/agenda/agendamento-cadastro');
  }

  goToAgendamentoCadastro2(tab?) {
    this.closeSideBar();
    if (tab){
      this.router.navigate(['/ambiente/agenda/agendamento-cadastro/', tab]);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    this.router.navigateByUrl('ambiente/agenda/agendamento-cadastro');
  }

  goToUserProfile(){
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/user-profile');
  }

  goToAgendamentoList() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/agendamento-list');
  }

  goToAgendaHistory() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/agenda-history');
  }

  goToFinancial() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/financeiro/1');
  }

  goToFinancial2() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/financeiro/2');
  }

  goToListaEspera() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/lista-espera');
  }

  goToClubeDeAssinaturas() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/clube-de-assinaturas');
  }

  goToPacotes() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/pacotes');
  }

  openExternalLink(linkParam) {
    window.open(linkParam, '_blank');
  }

  openSuporte() {
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      window.open("https://wa.me/553591060515?&text=Ol%C3%A1%2C%20preciso%20de%20suporte!", "_blank");
    } else {
      window.open("https://web.whatsapp.com/send?phone=553591060515&text=Ol%C3%A1%2C%20preciso%20de%20suporte!", "blank");
    }

    this.closeSideBar();
  }

  openSideBar() {
    document.getElementById("sidebar").style.left = "0";
    this.globals.showPageMainContent = false;
  }

  closeSideBar(){

    if(this.globals.showPageMainContent || this.router.url.includes('agenda-home')){
      return;
    }

    document.getElementById("sidebar").style.left = "-100%";
    this.globals.showPageMainContent = true;
  }

  goToCores() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/cores');
  }

  isPendingPaymentOneDayTolerance() {
    const assinaturaFimTmp = localStorage.getItem('currentEnd');

    if (assinaturaFimTmp) {
      const assinaturaFimDate = new Date(assinaturaFimTmp);
      assinaturaFimDate.setHours(0,0,0,0);

      const assinaturaFimDatePlusOneDay = assinaturaFimDate.setDate(assinaturaFimDate.getDate() + 1);
      const today = new Date().getTime();
      if (today < assinaturaFimDatePlusOneDay) {
        return true;
      }
    }

    return false;
  }

  private getLink(){
    this.compSvc.getMeusLinks(CompanyUtils.getCompanyId()).pipe(take(1)).subscribe({
      next: (result) => {
        this.company = result[0];
        this.company.img_cartao_link = CompanyUtils.getImgCartaoLink();
      },
      error: () => {
        this.globals.presentToast("Não foi possível trazer o seu link de agendamento");
      }
    });
  }


  private getAgendaCount(agendamentoSvc: AgendamentoService, globals: Globals, alarmTimeOut, agendaCountTimeout, loopAlarm) {

    if(CompanyUtils.getCompanyId() != undefined){
      agendamentoSvc.getCount().subscribe(
        (result) => {

          const currentCount = Number.parseInt(result);

          if (globals.agendaCount == undefined) {
            globals.agendaCount = currentCount;
          }
          else if (currentCount > globals.agendaCount) {
            globals.showAgendaRefreshPrompt = true;
            globals.agendaCount = currentCount;
            if(globals.playAlarm == 1){
              globals.alarm = new Audio('../../assets/audio/ring.mp3');
              globals.alarm.play();
            }
            else if(globals.playAlarm == 2){
              globals.stopAlarm = false;
              loopAlarm(globals, alarmTimeOut, loopAlarm);
            }
          }

          if(globals.checkAgenda){
            agendaCountTimeout = setTimeout(() => {
              this.getAgendaCount(agendamentoSvc, globals, alarmTimeOut, agendaCountTimeout, loopAlarm);
            }, 30000);
          }

        },
        (err) => {
          console.log(err);
        }
      );

    }
    else {
      if(globals.checkAgenda){
        agendaCountTimeout = setTimeout(() => {
          this.getAgendaCount(agendamentoSvc, globals, alarmTimeOut, agendaCountTimeout, loopAlarm);
        }, 30000);
      }
    }
  }

  private loopAlarm(globals: Globals, alarmTimeOut, loopAlarm){

    globals.alarm = new Audio('../../assets/audio/ring.mp3');
    globals.alarm.play();

    alarmTimeOut = setTimeout(() => {
      if(!globals.stopAlarm){
        loopAlarm(globals, alarmTimeOut, loopAlarm);
      }
    }, 5000);

  }

  pauseAlarm(){
    this.globals.stopAlarm = true;
    this.globals.showRefreshPrompt = false;
    this.globals.showAgendaRefreshPrompt = false;
    if(this.globals.alarm != undefined){

      this.globals.alarm.pause();
    }
    clearTimeout(this.alarmTimeOut);
  }

  newAgendaRedirect(){

    if(this.globals.shouldRedirect){
      this.router.navigateByUrl('ambiente/agenda/agendamento-list');
    }

  }

  setSelectedButton(index: number): void {
    this.selectedButton = index;
    localStorage.setItem('selectedButton', index.toString());  // Armazene a seleção no localStorage
  }

  openMenu() {
    this.router.navigate(['ambiente/agenda/products']);
  }

  windowReload() {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

}
