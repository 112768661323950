import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'capitalizeFirstLetter'})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  transform(pStr: any): string {
    if (pStr == null) {
      return ;
    } else if (pStr.length == 0) {
      return '';
    }
    return pStr.charAt(0).toUpperCase() + pStr.slice(1);
  }
}
