
<div class="mainPage">
  
  <div class="centerInScreen">
    <form class="formLogin">
      <div>

        <img src="assets/logo.png"/>

        <div class="form-field">
          <div>
            <label class="labelCampos">Nova senha</label>
            <input class="camposCadastro" type="password" [(ngModel)]="data.password" name="pass" placeholder="Insira a nova senha"/>
          </div>

          <div>
            <label class="labelCampos">Confirmar senha</label><br/>
            <input class="camposCadastro" type="password" [(ngModel)]="passAux" name="aux" placeholder="Reescreva a senha inserida acima">
          </div>

          <div class="cadastreLabelDiv">
            <button class="btnResgate largeBtn" (click)="change()">Alterar</button>
          </div>

          <div class="cadastreLabelDiv" style="margin-bottom: 10%;">
            <button class="btnResgate largeBtn" (click)="goToProfile()">Cancelar</button>
          </div>
        </div>
        
      </div>
    </form>
  </div>

</div>