import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Config } from '../../config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';


@Injectable({
  providedIn: 'root'
})
export class PayRegisterService extends BaseService {
  baseURL = `${Config.vBusUrl}pay_register_company/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  register(regObj) {
    const pass = Md5.hashStr(regObj.rawPassword);
    regObj.password = pass.toString();
    regObj.rawPassword = '';
    
    const obj = JSON.stringify(regObj);
    return this.http.post(this.baseURL, obj)
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data;
        }),
        catchError(this.handleErrors)
    );
  }

}
