export interface AgendamentoEsperaInterface{

    agendamento_espera_id: number;
    company_id: number
    data_agendamento: any;
    nome_cliente: string;
    telefone_cliente: string;
    servico_name: string;
    prestador_name: string;
    appuser_id: number;
    preco: number;
    preco_mode: number;
    campo_flexivel_label: string;
    campo_flexivel: string;
}

export class AgendamentoEspera implements AgendamentoEsperaInterface{

    agendamento_espera_id: number;
    company_id: number
    data_agendamento: any;
    nome_cliente: string;
    telefone_cliente: string;
    servico_name: string;
    prestador_name: string;
    appuser_id: number;
    data_agendamentoView: string;
    preco: number;
    precoStr: string;
    preco_mode: number;
    campo_flexivel_label: string;
    campo_flexivel: string;

    isSended: boolean;
}