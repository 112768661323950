import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class PrestadorPublService extends BaseService {

  baseURL = `${Config.vWebUrl}prestador_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAll(company_id){
    return this.http.get(this.baseURL + `-1/${company_id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  // getPrestadorWorkhours(prestador_id){
  //   return this.http.get(this.baseURL + `-3/${prestador_id}`, {
  //     context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
  //   })
  //   .pipe(
  //     map((data: any) => {
  //       //
  //       if (data.status != "200") {
  //         catchError(this.handleErrors);
  //       }
  //       return data.result;
  //     }),
  //     catchError(this.handleErrors)
  //   );
  // }

  getPrestadorWithServiceIdLst(company_id) {
    return this.http.get(this.baseURL + `-4/${company_id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getPrestadorWorkhoursOfDay(prestador_id, dateStr){
    return this.http.get(this.baseURL + `${prestador_id}/${dateStr}/-1`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  // getPrestadorPhoneById(prestador_id){
  //   return this.http.get(this.baseURL + `/${prestador_id}`, {
  //     context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
  //   })
  //   .pipe(
  //     map((data: any) => {
  //       if (data.status != "200") {
  //         throw new Error('Código de status diferente de 200');
  //       }
  //       return data.result;
  //     }),
  //     catchError(this.handleErrors)
  //   );
  // }


}
