import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagePayRegisterRoutingModule } from './page-pay-register-routing.module';
import { PagePayRegisterComponent } from './page-pay-register.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PriceBRModule } from '../svc/priceBR/priceBR.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule } from '@angular/common/http';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrMaskerModule } from 'br-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};


@NgModule({
  declarations: [
    PagePayRegisterComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    PriceBRModule,
    FormsModule,
    NgxUiLoaderModule,
    HttpClientModule,
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    NgbModule,
    BrMaskerModule,
    PagePayRegisterRoutingModule
  ]
})
export class PagePayRegisterModule { }
