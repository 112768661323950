import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { BaseService2 } from '../baseservice2';
import { CadastroConfig } from '../cadastro_config';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class CadastrarService extends BaseService2 {
  baseURL = `${CadastroConfig.apiUrl}cadastrar_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  cadastrar(cadatroObj) {
    let password = Md5.hashStr(cadatroObj.password);
    cadatroObj.password = password;

    let obj = JSON.stringify(cadatroObj);
    return this.http.post(this.baseURL, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
      .pipe(
        map((data: any) => {
          //
          if (data.status != "200") {
            catchError(this.handleErrors);
          }
          return data;
        }),
        catchError(this.handleErrors)
      );
  }
}
