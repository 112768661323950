import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
// import { Globals } from "../../globals";
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    // private globals: Globals,
    private authSvc: AuthService) {
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    // if(route.routeConfig.path != '/' 
    //   && route.routeConfig.path != 'ambiente/agenda/usage-plans'
    //   && route.routeConfig.path != 'company/reset-pass'){
    //     this.globals.companyNoPay = localStorage.getItem('cmpBlocked') == '1';
    // }
    // else{
    //   this.globals.companyNoPay = false;
    // }

    
    if (localStorage.getItem("cmpToken") != null) {
      return true;
    } else {
      this.authSvc.redirectUrl = state.url;
      this.router.navigate(['/empresa/login']);
      return false;
    }
  }

}
