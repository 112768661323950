import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mapTo, startWith, timeout } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { EMPTY, fromEvent, merge, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService  extends BaseService{

  baseURL = `${Config.vWebUrl}pedido_edp/`;
  connectionMonitor: Observable<boolean>;

  constructor(private h: HttpClient,
    @Inject(PLATFORM_ID) platform) {
    super(h);

    if (isPlatformBrowser(platform)){
      const offline$ = fromEvent(window, 'offline').pipe(startWith(!window.navigator.onLine), mapTo(false));
      const online$ = fromEvent(window, 'online').pipe(startWith(window.navigator.onLine), mapTo(true));
      this.connectionMonitor = merge(
        offline$, online$
      );
    } else {
      this.connectionMonitor = EMPTY;
    }
  }

  monitor(): Observable<boolean> {
    return this.connectionMonitor;
  }

  
}
