import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { Appuser } from '../../appuser/appuser';
import { Assinatura } from './assinatura';
import { PaymentMethod } from '../../payment-method/payment-method';

export class AssinaturaCliente {
    appuser_subscription_id: number;
    appuser_id: number;
    subscription_id: number;
    paid_value: string;
    payment_method: number;
    status: number; // sempre 2 para requisições
}

export class AssinaturaClienteInterface {
    appuser_subscription_id: number;
    appuser: Appuser;
    subscription: Assinatura;
    payment_method: PaymentMethod;
    paid_ammount: string;
    ammount_to_pay: number;
    starts_at: any;
    expires_at: any;
    status: number;
}

export class SubscriptionRequest {
    appuser_subscription_id: number;
    appuser: Appuser;
    subscription: Assinatura;
}