<!-- [ngClass]="{'selectedCardChoice' : (modalidade_frete === 1)}" -->
<!-- [ngStyle]="{'background-color': switchBackColor(fdi)}" -->

<ngx-ui-loader></ngx-ui-loader>


<div id="unavailableDiv" class="unavailableDiv" *ngIf="company.blocked_by_payment" [style.height.px]="unavailableDivHeight">

    <img src="assets/utilities/block_black_24dp.svg" alt="Indisponivel">

    <div>
      <h2>Link indisponível.</h2>
      <p>Entre em contato com o estabelecimento</p>
    </div>

</div>


<ng-template #content let-modal>
  <div class="modal-content" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}">
  <div class="modal-header backToAgendaModalHeader">
    <button type="button" id="dismissContentModal" class="btn-close" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    <h5 class="modal-title" id="confirmaAgendamentoModalHead">Voltar para agenda</h5>
  </div>
  <div class="modal-body" [class]="[currentTheme]">

    <div id="confirmaAgModalBody" class="modal-body">

      <div class="col">

        <div class="row">
          <p class="confAgendP text-uppercase">Revise o seu Agendamento</p>
        </div>

        <div *ngFor="let a of agendamentoCart">

          <div class="row alignLeft">
            <div class="icon">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/servico.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>{{a.servico_name}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="icon">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/prestador.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>{{a.prestador_name}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="icon">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/horario.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>Dia {{a.data_agendamentoView}} às {{a.time_start}}, duração: {{a.duracao}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="icon">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/local.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p>{{company.address_complete}}</p>
            </div>
          </div>

          <div class="row alignLeft">
            <div class="icon">
              <img
                class="agendamentoIconImg"
                src="assets/agendamento-publ/moedas.png"
                alt="icon"
                [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}"
              >
            </div>
            <div class="col-10">
              <p *ngIf="a.preco_mode == 1">R${{a.preco*a.vagas | priceBR}}</p>
              <p *ngIf="a.preco_mode == 2">Preço sob consulta</p>
              <p *ngIf="a.preco_mode == 3">A partir de R${{a.preco*a.vagas | priceBR}}</p>
            </div>
          </div>

          <div class="d-flex justify-content-center" style="margin-top: 10px;">
            <p class="confAgendP text-uppercase" style="margin-bottom: 8px;">Preencha os campos</p>
          </div>

          <div class="alignLeft" *ngFor="let n of a.namesNgFor; let i = index">
            <label for="nome-cliente" class="form-label confirmaFieldLabels">Nome *</label>
            <div class="input-group mb-3">
              <input type="text" maxlength="100" class="form-control" id="nome-cliente" [(ngModel)]="a.names[i]">
            </div>
          </div>

          <div class="alignLeft">
            <label for="telefone-cliente" class="form-label confirmaFieldLabels">Telefone *</label>
            <div class="input-group mb-3">
              <input type="text" inputmode="tel" class="form-control" maxlength="15" id="telefone-cliente" [(ngModel)]="a.telefone_cliente" mask="(00) 00000-0000" [dropSpecialCharacters]="false">
            </div>
          </div>

          <div class="alignLeft" *ngIf="vagasDisponiveis > 1">
            <label for="vagas-cliente" class="form-label confirmaFieldLabels">Reservar para quantos ({{vagasDisponiveis}} disponíveis) *</label>
            <div class="input-group mb-3">
              <input type="number" maxlength="6" inputmode="numeric" class="form-control" id="vagas-cliente" [(ngModel)]="a.vagas" (ngModelChange)="updateNames(a)">
            </div>
          </div>

          <div class="alignLeft">
            <label for="nome-cliente" class="form-label confirmaFieldLabels">Observação (Opcional)</label>
            <div class="input-group mb-3">
              <input type="text" maxlength="100" class="form-control" id="nome-cliente" [(ngModel)]="a.obs">
            </div>
          </div>

          <div class="alignLeft" *ngIf="company.campo_flexivel_label">
            <label for="campo-flex" class="form-label confirmaFieldLabels">{{company.campo_flexivel_label}} {{company.is_campo_flexivel_mandatory ? '*' :'(Opcional)'}}</label>
            <div class="input-group mb-3">
              <input type="text" maxlength="100" class="form-control" id="campo-flex" [(ngModel)]="a.campo_flexivel">
            </div>
          </div>

        </div>

        <!-- <div *ngIf="company.pix_enable">

          <div class="autoLayout flex-column align-items-start">
            <label class="marginVerticalMedium">PIX para pagamento antecipado</label>

            <label>Chave {{pixKeyType}}</label>
            <label>{{pixKey}}</label>
            <label>Beneficiado: {{nomeContaPix}}</label>
          </div>

          <div class="autoLayout marginVerticalMedium flex-column w-100" *ngIf="company.price_perc">
            <label class="marginVerticalSmall text-center w-100">Pague {{company.price_perc * 100}}% no momento do agendamento e envie o comprovante pelo WhatsApp</label>
            <label class="text-center w-100">Valor de pagamento: {{pixValue | currency: 'BRL'}}</label>
          </div>

        </div> -->

        <div *ngIf="company.pix_enable" class="pix-container">

          <div class="pix-header" *ngIf="company.price_perc">
              <p>Para realizar o agendamento é necessário realizar o PIX de {{company.price_perc * 100}}% do valor total</p>
              <p class="pix-amount-label">Realize o PIX de</p>
              <p class="pix-amount-value"> {{pixValue | currency: 'BRL'}}</p>
          </div>
      
          <div class="pix-info">
              <p class="pix-key-label">Chave PIX - {{pixKeyType}}</p>
              <div class="pix-key-container">
                  <input type="text" [value]="pixKey" readonly class="pix-key-input" id="pixKeyInput">
                  <button (click)="copyPixKey()" class="pix-copy-button">
                      <img src="assets/icon-copy.png" alt="Copiar">
                  </button>
              </div>
              <p class="pix-beneficiary">{{nomeContaPix}}</p>
              <p class="pix-instructions">ENVIE O COMPROVANTE DO PIX PARA O WHATSAPP DO ESTABELECIMENTO</p>
          </div>
      
      </div>
      

      </div>

    </div>

    <div class="row d-flex justify-content-center marginVerticalSmall">
      <button class="maisServ" (click)="continueBooking()">Escolher mais serviços</button>
    </div>

    <div class="row d-flex justify-content-center marginVerticalSmall">
      <button class="avancaBtnModal " (click)="salvarAgendamento()">Finalizar Agendamento</button>
    </div>

  </div>
</div>
</ng-template>

<!-- Lista de espera modal -->
<ng-template #espera let-modal>
  <div class="modal-header backToAgendaModalHeader" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}">
    <button type="button" id="dismissEsperaModal" class="btn-close" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    <h5 class="modal-title" id="confirmaAgendamentoModalHead">Voltar para agenda</h5>
  </div>
  <div class="modal-body">

    <div id="confirmaAgModalBody" class="modal-body">

      <div class="col">

        <div class="row">
          <p class="confAgendP text-uppercase">Entrar em lista de espera para</p>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/servico.png" alt="icon">
          </div>
          <div class="col-10">
            <p>{{agendamentoAFazer.servico_name}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/prestador.png" alt="icon">
          </div>
          <div class="col-10">
            <p>{{agendamentoAFazer.prestador_name}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/horario.png" alt="icon">
          </div>
          <div class="col-10">
            <p>Dia {{agendamentoAFazer.data_agendamentoView}}, duração: {{agendamentoAFazer.duracao}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/local.png" alt="icon">
          </div>
          <div class="col-10">
            <p>{{company.address_complete}}</p>
          </div>
        </div>

        <div class="row alignLeft">
          <div class="col-2">
            <img class="agendamentoIconImg" src="assets/agendamento-publ/moedas.png" alt="icon">
          </div>
          <div class="col-10">
            <p *ngIf="agendamentoAFazer.preco_mode == 1">R${{agendamentoAFazer.preco*agendamentoAFazer.vagas | priceBR}}</p>
            <p *ngIf="agendamentoAFazer.preco_mode == 2">Preço sob consulta</p>
            <p *ngIf="agendamentoAFazer.preco_mode == 3">A partir de R${{agendamentoAFazer.preco*agendamentoAFazer.vagas | priceBR}}</p>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 10px;">
          <p class="confAgendP text-uppercase" style="margin-bottom: 8px;">Preencha os campos</p>
        </div>

        <label for="nome-cliente" class="form-label confirmaFieldLabels">Nome *</label>
        <div class="input-group mb-3">
          <input type="text" maxlength="100" class="form-control" id="nome-cliente" [(ngModel)]="agendamentoEspera.nome_cliente">
        </div>

        <div class="alignLeft">
          <label for="telefone-cliente" class="form-label confirmaFieldLabels">Telefone *</label>
          <div class="input-group mb-3">
            <input type="text" inputmode="tel" class="form-control" maxlength="15" id="telefone-cliente" [(ngModel)]="agendamentoEspera.telefone_cliente" mask="(00) 00000-0000" [dropSpecialCharacters]="false">
          </div>
        </div>

      </div>

    </div>

    <div class="row d-flex justify-content-center">
      <button class="avancaBtn" (click)="salvarListaEspera()">Avançar</button>
    </div>

  </div>
</ng-template>




<!-- div confirmacao fim -->
<div class="modal modaisConfirm" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}" id="confirmsBookingDone" tabindex="-1" aria-labelledby="confirmsBookingDone" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content text-center" [ngClass]="{'box-shadow-dark': currentTheme === 'theme-dark'}">
      <div class="modal-header backgroundNeutralColor100">
        <h5 class="modal-title">Agendamento realizado com SUCESSO</h5>
        <button hidden type="button" class="close" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}" data-bs-dismiss="modal" aria-label="Close" id="dismissconfirmsBookingDone">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body d-flex flex-column backgroundNeutralColor100">

        <div class="justify-content-center marginVerticalMedium">
          <img class="greenOkIcon" src="assets/agendamento-publ/greenOkIcon.png" alt="">
        </div>

        <div>
          <p>Seu agendamento foi enviado para o estabelecimento.</p>
        </div>

        <div>
          <button class="agendarBtn backgroundColorBlue2271A0" (click)="finalizeBooking()">Finalizar</button>
        </div>

      </div>

    </div>
  </div>
</div>
<button hidden="true" id="openconfirmsBookingDone" data-bs-toggle="modal" data-bs-target="#confirmsBookingDone">Hidden Button</button>
<!-- div confirmacao fim -->

<!-- div envio mensagem WhatsApp -->
<div class="modal" id="enviaMensagemWhats" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}" tabindex="-1" aria-labelledby="enviaMensagemWhats" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modaisConfirm" [class]="[currentTheme]">
    <div class="modal-content text-center" [ngClass]="{'box-shadow-dark': currentTheme === 'theme-dark'}">
      <div class="modal-img justify-content-center marginVerticalMedium">
        <img class="greenOkIcon" src="assets/agendamento-publ/greenOkIcon.png" alt="">
      </div>
      <div class="modal-header">
        <h5 class="modal-title">Finalize pelo WhatsApp</h5>
      </div>
      <div class="modalconfirm modal-body d-flex flex-column">
        <p class="textconfirm">O estabelecimento já recebeu o seu pedido, mas certifique-se enviando a mensagem pelo WhatsApp.</p>
        <p class="textconfirm">Essa mensagem serve para que o estabelecimento tenha mais uma confirmação de seu agendamento.</p>
        <div>
          <button class="agendarBtn" (click)="sendWhatsappMessage()">Enviar mensagem no WhatsApp</button>
        </div>
      </div>
    </div>
  </div>
</div>
<button hidden="true" id="openEnviaMensagemWhats" data-bs-toggle="modal" data-bs-target="#enviaMensagemWhats">Hidden Button</button>
<!-- div prompt envio mensagem WhatsApp -->




<!-- div confirmacao espera -->
<div class="modal modaisConfirm" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}" id="confirmsEsperaDone" tabindex="-1" aria-labelledby="confirmsEsperaDone" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content text-center" [ngClass]="{'box-shadow-dark': currentTheme === 'theme-dark'}">
      <div class="modal-header">
        <h5 class="modal-title">SUCESSO</h5>
      </div>

      <div class="modal-body d-flex flex-column">

        <div class="justify-content-center marginVerticalMedium">
          <img class="greenOkIcon" src="assets/agendamento-publ/greenOkIcon.png" alt="">
        </div>

        <div>
          <p>Seu agendamento foi adicionado na lista de espera do estabelecimento.</p>
        </div>

        <div>
          <button class="agendarBtn backgroundColorBlue2271A0" data-bs-dismiss="modal">Finalizar</button>
        </div>

      </div>

    </div>
  </div>
</div>
<button hidden="true" id="openConfirmsEsperaDone" data-bs-toggle="modal" data-bs-target="#confirmsEsperaDone">Hidden Button</button>
<!-- div confirmacao espera -->


<!-- modal sobre -->
<div class="modal fade info" id="exampleModalinfo" tabindex="-1" aria-labelledby="exampleModalinfoLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" [ngClass]="{'dark-class': currentTheme === 'theme-dark'}">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalinfoLabel">Informações do estabelecimento</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-start">
        <h4 class="modal-info-title text-start">Sobre o estabelecimento: </h4>
        <div class="modal-info-description">
          <p>{{company.description}}</p>
        </div>

        <h4 class="modal-info-title text-start">Endereço: </h4>
        <p class="address">{{company.address_complete}} - {{company.city_name}} - {{company.state_name}}</p>
      </div>
      <div class="modal-footer-info">
        <button type="button" class="btn close-info" data-bs-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
<!-- end modal sobre -->


<div class="modal fade info" id="acessoModal" tabindex="-1" aria-labelledby="acessoModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" *ngIf="!isClientLoggedin">
      <div class="modal-header backToAgendaModalHeader" [class]="[currentTheme]">
        <button hidden type="button" id="dismissAcessoModal" data-bs-dismiss="modal"></button>
        <h5 class="modalAcesso" id="confirmaAgendamentoModalHead">REALIZE O SEU ACESSO</h5>
      </div>
      <div class="modal-body">

        <div class="row d-flex justify-content-center marginVerticalMedium paddingHorizontalMedium">
          <button class="btnContainedOrange borderRadiusBig textMedium paddingVerticalMedium" style="background: #367925; border: none; text-transform: uppercase;" (click)="navigateToHome()">Acesso do estabelecimento</button>
        </div>

        <div class="row d-flex justify-content-center marginVerticalBig paddingHorizontalMedium">
          <button class="btnContainedOrange borderRadiusBig textMedium paddingVerticalMedium" style="text-transform: uppercase;" (click)="navigateToAcessoCliente()">Acesso do cliente</button>
        </div>

      </div>
    </div>
    <div class="modal-content" *ngIf="isClientLoggedin">
      <div class="modal-body">
        <div class="modal-header backToAgendaModalHeader" [class]="[currentTheme]">
          <button hidden type="button" id="dismissAcessoModal" data-bs-dismiss="modal"></button>
          <h5 class="modalAcesso" id="confirmaAgendamentoModalHead">BEM-VINDO</h5>
        </div>
        <div class="row d-flex justify-content-center marginVerticalMedium paddingHorizontalMedium">
          <button class="btnContainedOrange borderRadiusBig textMedium paddingVerticalMedium" style="background: #367925; border: none; text-transform: uppercase;" (click)="goToClientAgendamentoList()">Acesse seu histórico</button>
        </div>

        <div class="row d-flex justify-content-center marginVerticalBig paddingHorizontalMedium">
          <button class="btnContainedOrange borderRadiusBig textMedium paddingVerticalMedium" style="text-transform: uppercase;" (click)="logout()">Sair da conta</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="agendamentoRootDiv" [class]="[currentTheme]">

  <div class="banner">
    <img src="{{company.img_banner_link}}" class="cover" alt="">
  </div>

  <div class="bannerProfileImg"
  *ngIf="company.img_cartao_link != undefined"
  [style.backgroundColor]="currentTheme === 'theme-dark' ? '#0D0D0C' : '#f2f2f3'">
 <div class="profileDiv">
      <img src="{{company.img_cartao_link}}" class="img-fluid profile" alt="">
    </div>
  </div>

  <div id="companyHeaderInfo" class="row mw-100 ms-0">
    <div class="col-12 d-flex align-items-center header-container">

        <!-- <button class="aboutBtn" data-bs-toggle="modal" data-bs-target="#exampleModalinfo" style="position: relative; left: 7%;">
            <img src="assets/icons/about.png" alt="info" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
        </button> -->

        <p class="company-name">{{company.nome_fantasia}}</p>

        <!-- <div class="icon-container">
          <a [attr.href]="'https://api.whatsapp.com/send?phone=' + formatWhatsAppNumber(company.whatsapp_phone)" target="_blank">
              <button class="aboutBtn">
                  <img src="assets/utilities/whatsapp.png" alt="info" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
              </button>
          </a> -->
        
          <!-- <a [attr.href]="link_social" target="_blank">
              <button class="aboutBtn">
                  <img src="../../assets/icons/acesso.png" alt="acesso" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
              </button>
          </a>
        </div> -->

    </div>
</div>


  
  

  <!-- <ng-template *ngIf="company.description != undefined && company.description != ''"> -->
    <div class="autoLayout paddingVerticalSmall paddingHorizontalMedium w-100 apply-theme">
      <label class="body1Regular textMedium neutralColor600 text-center">{{company.description}}</label>
    </div>

    <div class="d-flex flex-column align-items-center apply-theme">
  
      <div class="selectCardDiv d-flex justify-content-left clube-assinatura-card agend" data-bs-toggle="modal" data-bs-target="#acessoModal">
        <p>Meus agendamentos</p>
      </div>

      <!--
      <a *ngIf='subscriptionCount > 0' (click)="goToClubeDeAssinaturas()" [ngStyle]="{'text-decoration': 'none'}" class="selectCardDiv d-flex justify-content-left clube-assinatura-card clube">
        <p>Clube de assinatura</p>
      </a>
      -->

    </div>

  <!-- </ng-template> -->


  <div class="agendamento-section-options">

    <div class="row d-flex justify-content-center marginVerticalMedium">

      <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium">

        <div class="selectCardDiv d-flex justify-content-center flex-column">
          <p class="selectIt">Selecione a data</p>

          <button class="dateSelectionBtn marginVerticalSmall" (click)="toggleShowDatepicker()">
            <p>{{filterDateStartView}}</p>
            <i class="fa fa-calendar faIcon" aria-hidden="true"></i>
          </button>

          <div *ngIf="showDatePicker" class="marginVerticalSmall" style="text-align: center;">
            <div style="display: flex; justify-content: space-evenly;">
              <ngb-datepicker style="background-color: white;" [(ngModel)]="filterDateStart" (dateSelect)="toggleShowDatepicker()"></ngb-datepicker>
            </div>
          </div>

        </div>

      </div>

      <div class="productCategoryHorScroll paddingHorizontalMedium w-100">
        <div *ngFor="let categItemHorizontal of serviceCategoryList; let categIndex = index;">

          <div class="productCategoryHor marginHorizontalSmall" (click)="scrollToCategory(categIndex)">
            <img class="roundImg" src="{{categItemHorizontal.imgUrl}}" alt="imagem categoria">
            <p>{{categItemHorizontal.name}}</p>
          </div>

        </div>
      </div>

      <div class="testecol-12 col-sm-12 d-flex justify-content-center marginVerticalMedium">

        <div class="selectServicos d-flex justify-content-center flex-column">
          <p class="selectIt">Selecione o serviço</p>

          <div
            class="d-flex justify-content-center marginVerticalSmall"
            *ngFor="let categItemVertical of serviceCategoryList; let categIndex = index;"
          >

            <div class="categoryVerticalDiv d-flex flex-column align-items-center" id="categItemVertical{{categIndex}}">

              <div class="d-flex align-items-center productCategoryVert" (click)="expandCategory(categItemVertical)">

                <label>{{categItemVertical.name}}</label>

                <button>
                  <label *ngIf="!categItemVertical.expanded">Veja mais</label>
                  <label *ngIf="categItemVertical.expanded">Veja menos</label>
                </button>

              </div>

              <div *ngIf="categItemVertical.expanded" style="width: 100%;">
                <div *ngFor="let opc of categItemVertical.serviceList; let opcIndex = index">

                  <div class="row d-flex justify-content-left align-items-center itemSelectionDiv" (click)="onServicoSelected(categItemVertical, opc.servico_id)">

                    <div class="col-3 text-center">
                      <img *ngIf="opc.img_url == undefined" src="{{opc.image_link}}" alt="imagem do serviço">
                      <img *ngIf="opc.img_url != undefined" src="{{opc.img_url}}" alt="imagem do serviço">
                    </div>

                    <div class="col-7 d-flex align-items-start flex-column justify-content-left align-self-start">
                      <p class="svcOpc">{{opc.name}}</p>

                      <p class="descriptionP">{{opc.description}}</p>
                    </div>

                    <div class="col-2">
                      <input type="radio" [value]="true" id="servRadio{{categIndex}}{{opcIndex}}" name="radioIntroducedOpt{{categIndex}}{{opcIndex}}" [(ngModel)]="opc.selected"
                         class="deliveryMethodRadio">
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

      <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium" *ngIf="agendamentoAFazerStatuses.serviceSelected">

        <div class="selectCardDiv d-flex justify-content-center flex-column">
          <p class="selectIt">Selecione o profissional</p>

          <div>
            <div *ngFor="let opc of prestadorLstView; index as opcIndex">

              <div class="row d-flex justify-content-left align-items-center itemSelectionDiv" (click)="onPrestadorSelected(opc.prestador_id)">

                <div class="col-3 text-center">
                  <img class="roundImg" *ngIf="opc.img_url == undefined" src="{{opc.image_link}}" alt="imagem do profissional">
                  <img class="roundImg" *ngIf="opc.img_url != undefined" src="{{opc.img_url}}" alt="imagem do profissional">
                </div>

                <div class="col-7 d-flex align-items-start flex-column justify-content-left align-self-start">
                  <p style="font-weight: bold;">{{opc.prestadorName}}</p>

                  <p class="descriptionP">{{opc.description}}</p>
                </div>

                <div class="col-2">
                  <input type="radio" [value]="true" id="prestRadio_{{opcIndex}}" name="prestRadio_{{opcIndex}}" [(ngModel)]="opc.selected"
                     class="deliveryMethodRadio">
                </div>
              </div>

            </div>
          </div>


          <div class="row" style="padding: 0 15%; width: 100%;" *ngIf="agendamentoAFazer.preco != undefined || agendamentoAFazer.duracao != undefined">

            <div class="col-6 text-center" *ngIf="agendamentoAFazer.duracao != undefined">
              <img class="agendamentoIconImg" src="assets/agendamento-publ/horario.png" alt="icon" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
              <p class="agendamento-a-fazer-info1 neutralColor700">{{agendamentoAFazerDuracao}}</p>
            </div>

            <div class="col-6 text-center" *ngIf="agendamentoAFazer.preco != undefined">
              <img class="agendamentoIconImg" src="assets/agendamento-publ/moedas.png" alt="icon" [ngClass]="{'invertColor' : (currentTheme === 'theme-dark')}">
              <p class="agendamento-a-fazer-info1 neutralColor700" *ngIf="agendamentoAFazer.preco_mode == 1">R${{agendamentoAFazer.preco | priceBR}}</p>
              <p class="agendamento-a-fazer-info1 neutralColor700" *ngIf="agendamentoAFazer.preco_mode == 2">Preço sob consulta</p>
              <p class="agendamento-a-fazer-info1 neutralColor700" *ngIf="agendamentoAFazer.preco_mode == 3">A partir de R${{agendamentoAFazer.preco | priceBR}}</p>
            </div>
          </div>

        </div>

      </div>

      <div class="col-12 col-sm-12 d-flex justify-content-center marginVerticalMedium" *ngIf="agendamentoAFazerStatuses.prestadorSelected">

        <div class="selectCardDiv d-flex justify-content-center flex-column">
          <p class="selectIt">Selecione o horário</p>

          <div>
            <div class="row justify-content-center" id="horariosMainDiv">
              <div *ngIf="loadingHorarios">
                <!-- loading indicator -->
                <div class="infoLoader2">
                </div>
              </div>
              <div *ngIf="!loadingHorarios && showNoOpenSchedule" class="autoLayout paddingVerticalSmall flex-column text-center w-100">
                <label style="font-size: 24; font-style: italic;">Nenhum horário disponível</label>
                <label class="body1Regular textSmall neutralColor600 marginVerticalMedium paddingHorizontalMedium">
                  A agenda desse profissional pode estar cheia para este dia, verifique os próximos dias ou uma possibilidade de encaixe.
                </label>
                <button class="btnContainedOrange borderRadiusBig borderNone" style="background: #164775;" *ngIf="checkWorkhours() && company.lista_espera_enable"
                        (click)="avancaListaEspera(espera)">
                  Entrar em lista de espera
                </button>
              </div>
              <div *ngIf="!loadingHorarios" class="row">
                <div *ngFor="let horarioAEscolher of openSchedule; let ind = index" class="col-4 chooseHorarioDiv">
                  <button id="btnChooseHora{{ind}}" class="chooseHorarioBtn" [ngClass]="{'selectedHorario': horarioAEscolher.selected, 'horarioNotSelected': !horarioAEscolher.selected}" (click)="escolheHorario(horarioAEscolher)">{{horarioAEscolher.openScheduleStr}}</button>
                </div>
              </div>

            </div>
          </div>

          <p class="selectIt" *ngIf="agendamentoAFazerStatuses.timeSelected">Horário selecionado: {{agendamentoAFazer.time_start}}</p>

        </div>

      </div>

    </div>
    <div class="row d-flex justify-content-center avanca1Div" id="avanca1Div">
      <button hidden="true" id="openConfirmacaoAgendModal" data-bs-toggle="modal" data-bs-target="#confirmaAgendamentoModal">hidden button</button>
      <button class="avancaBtn" (click)="avancaParaConfirmar(content)">Avançar</button>
    </div>
  </div>


</div>
