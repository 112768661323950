
export class ProductAditional {
  product_aditional_id: number;
  name: string;
  price: number = 0;
  //
  added: boolean = false;
  order_quant: number = 0;
  priceStr: string;
  list_position: number;
  description: string;
  max_per_product: number;
}
