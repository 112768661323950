import { Injectable } from "@angular/core";
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class Globals {
    headerTitle: string = '';
    backRoute: string = '';
    showMenuBtn = false;

    servicos: any[] = [];  // Adicione esta linha para manter a lista de serviços

    showAdminOpt = false;

    alert = { title: '', message: '' };
    toast = '';
    confirm = '';
    showAlert = false;
    showToast = false;
    showToast2 = false;
    showConfirm = false;
    showRedirect = false;
    showSideBar = false;

    askedCookiesConsent = false;
    acceptedCookies = false;

    companyNoPay = false;
    planId: number = 0;
    showPaywallGlobals: boolean = false;

    playAlarm = -1;
    stopAlarm = false;
    showAlarmPrompt = false;    // TODO: bring it back to true
    alarm: HTMLAudioElement;
    showRefreshPrompt = false;
    shouldRedirect = true;
    refreshPedidosListFunction: () => void;
    isLoading = false;
    filterDateStart: NgbDateStruct;
    filterDateEnd: NgbDateStruct;
    filterStatusIds = [1, 2, 3, 4, 5, 6, 7];
    checkPedidos = true;

    showAgendaAlarmPrompt = true;
    showAgendaRefreshPrompt = false;
    agendaCount: number;
    checkAgenda = true;

    showPaymentPopup = false;
    showSubscriptionOver: boolean = false;
    showSubscriptionOverRibbon: boolean = false;
    paymentAlertImg = '';
    managePaymentUrl = '';

    showConnectionFail = false;

    showPageMainContent = true;

    showTopBar = true;

    constructor(private modalSvc: NgbModal) { this.checkPedidos = true;
    }

    /**
     * @deprecated Has to be fixed, it is not opening anything
     * @param title
     * @param message
     */
    openDialog(title, message) {
      // This function is not opening anything
        this.alert.title = title;
        this.alert.message = message;
        this.showAlert = true;
    }

    presentToast(message) {
        this.toast = message;
        this.showToast = true;
        this.delayToast();
    }

    presentToast2(title, message) {
      this.alert.title = title;
      this.toast = message;
      this.showToast2 = true;

      setTimeout(() => {
        this.showToast2 = false;
        this.toast = '';
        this.alert.title = '';
      }, 3000);
    }

    async delayToast() {
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.showToast = false;
    }

    openConfirm(message, handler: () => void) {
        this.confirm = message;
        this.showConfirm = true;
        this.confirmHandler = handler;
    }

    openRedirectModal(message, handler: () => void) {
      this.confirm = message;
      this.showRedirect = true;
      this.confirmHandler = handler;
  }

    confirmHandler() { }

    runHandler() {
        this.showConfirm = false;
        this.confirmHandler();
    }

    runRedirectHandler() {
      this.showRedirect = false;
      this.confirmHandler();
  }

    setShowPaywallGlobals(param) {
      this.showPaywallGlobals = param;
      localStorage.setItem('showPaywallGlobals', param);
    }

    getShowPaywallGlobals() {
      const res = localStorage.getItem('showPaywallGlobals');
      this.showPaywallGlobals = (res == 'true') ? true : false;
      return this.showPaywallGlobals;
    }

    closeSubscriptionOver() {
      window.open(this.managePaymentUrl, '_blank');
      this.showSubscriptionOver = false;
    }

}
