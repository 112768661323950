import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../svc/globals';
import { BrMaskerModule } from 'br-mask';
import { PriceBRModule } from '../../app/svc/priceBR/priceBR.module';
import { ClipboardModule } from 'ngx-clipboard';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxMaskModule, IConfig } from 'ngx-mask';

//components
import { AssinActionsComponent } from '../company/assin-actions/assin-actions.component';
import { PagarPlanoComponent } from '../company/pagar-plano/pagar-plano.component';
import { CompanyNewPasswordComponent } from '../company/company-new-password/company-new-password.component';
import { CompanyEditLoginComponent } from '../company/company-edit-login/company-edit-login.component';
// import { VisaoGeralComponent } from '../company/visao-geral/visao-geral.component';
import { CompanyTopBarComponent } from '../company/company-top-bar/company-top-bar.component';
import { SubscriptionTopbarRibbonModule } from '../subscription-topbar-ribbon/subscription-topbar-ribbon.module';


const maskModuleOptions: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AssinActionsComponent,
    PagarPlanoComponent,
    CompanyNewPasswordComponent,
    CompanyEditLoginComponent,
    // VisaoGeralComponent,
    CompanyTopBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxUiLoaderModule,
    ReactiveFormsModule,
    NgbModule,
    BrMaskerModule,
    PriceBRModule,
    ClipboardModule,
    QRCodeModule,
    SubscriptionTopbarRibbonModule,
    NgxMaskModule.forRoot(maskModuleOptions),
  ],
  providers: [
    Globals,
    NgbModule,
    {provide: LOCALE_ID, useValue: 'pt-BR'}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CompanyTopBarComponent]
})
export class CommonAmbienteModule { }
