import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { CompanyUser } from './CompanyUser';
import { BaseService } from '../base.service';
import { of } from 'rxjs';
import { Company } from '../company';

@Injectable({
  providedIn: 'root'
})

export class ProfileService extends BaseService{
  baseURL = `${Config.vBusUrl}company_user_profile/`;
  cmpURL = `${Config.vBusUrl}company_edp/`;

  companyMeuLinkData: Company;

  constructor(
    private h: HttpClient,
    // private companyUtils: CompanyUtils
  ) {
    super(h);
  }

  getInfo(id) {
    return this.http.get<CompanyUser>(this.baseURL + `${id}/-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result[0];
      }),
      catchError(this.handleErrors)
    );
  }

  getInfoType2(id) {
    return this.http.get<CompanyUser>(this.baseURL + `${id}/-2`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result[0];
      }),
      catchError(this.handleErrors)
    );
  }

  getListType2(id) {
    return this.http.get<CompanyUser>(this.baseURL + `${id}/-3`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getFrete(id) {
    return this.http.get<CompanyUser>(this.baseURL + `${id}/-4`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result[0];
      }),
      catchError(this.handleErrors)
    );
  }

  patch(regObj: CompanyUser) {
    const obj = JSON.stringify(regObj);
    return this.http.patch(this.baseURL, obj)
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

  post(regObj: CompanyUser) {
    const obj = JSON.stringify(regObj);
    return this.http.post(this.baseURL, obj)
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data;
        }),
        catchError(this.handleErrors)
    );
  }

  delete(id) {
    return this.http.delete(this.baseURL + `${id}/-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.message;
      }),
      catchError(this.handleErrors)
    );
  }

  getMeusLinks(company_id) {
    // little 'cache' implementation
    if (this.companyMeuLinkData?.handle) {
      return of(this.companyMeuLinkData);
    }
    return this.http.get(this.cmpURL + `-1/${company_id}`)
    .pipe(
      map((data: any) => {
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          if (data.result[0]) {
            data.result[0].handle = data.result[0].handle.replace('instapedido.com','whatsagenda.com.br');
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
}


  setMeusLinks(company_id, newHandle) {
    // Cria o objeto com os dados a serem atualizados
    const updateData = {
        handle: newHandle // Aqui, você envia apenas o handle, como 'fixesgggg'
    };

    // Realiza uma solicitação PUT para atualizar o handle da empresa
    return this.http.put(this.cmpURL + `-1/${company_id}`, updateData)
    .pipe(
        map((data: any) => {
            // Trata a resposta da API conforme necessário
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            // Aqui você pode ajustar o retorno conforme a necessidade
            return data.result;
        }),
        catchError(this.handleErrors)
    );
}


}
